import React from 'react';
//import "babel-polyfill";
import './polyfill-append';
import ReactDOM from 'react-dom';
import {Row, Col} from 'react-bootstrap';
import './css/index.css';
import Head from './Head';
import Configurator from './Configurator';
import Sunburst from './Sunburst';
import Force from './Force';
import Empty from './Empty';
import GalleryGrid from './ImageGrid';
import Help from './Help';
import _ from 'lodash';
//import Data from './Data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faInfo } from '@fortawesome/free-solid-svg-icons';
import * as rdd from 'react-device-detect';
import * as qs from 'query-string';

//import ReactGA from 'react-ga';
//ReactGA.initialize('UA-101871411-1');

class App extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			scope: "App",
			queryParams: null,
			width: 0,
			height: 0,
			device: null,
			isMobile: null,
			sunburstFirst: true,
//			sunburstfirstload: false,
			sunburstfirstload: true,
			forcefirstload: false,
			restartforce: true,
			currRecord: null,
			currData: null,
			currRandom: 1,
			extHTML: [],
			extHTMLfooter: null,
			extHTMLheader: null,
			myfilter: 4,
			mysearch: '',
			myfont: false,
			mythumbsize: false,
			myversalien: false,
			myorderchron: true,
			mysegmentfill: true,
			mydesign: "opaque",
//			mydesign: "gray",
			mysdesigngray: false,
			mysdesigncolor: true,
			mycolor: 10,
			mynetcolor: 2,
			selectedText: null,
			selectedNode: null,
			selectedImage: 0,
			isToggleSidepanelOn: true,
			isToggleHelppanelOn: false,   // set this to true to avoid helppanel on startup
		}

		this.handleClick = this.handleClick.bind(this);
		this.handleHelpClick = this.handleHelpClick.bind(this);

//		const isDesktopOrLaptop = useMediaQuery({query: '(min-device-width: 1224px)'});
//		const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' });
//		const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
//		const isTabletOrMobileDevice = useMediaQuery({query: '(max-device-width: 1224px)'});
//		const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
//		const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
//
//		const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
//		const isTablet = useMediaQuery({ query: '(min-width: 768, max-width: 991px)' });

	}


	l(string) {	console.log("%c"+string, "color: blue");}

	callbackSelected = (selected) => {
		this.setState({selectedText: selected.text });
		this.setState({selectedNode: selected.id });
	};


	callbackFilter = (formData) => {
		this.setState({currData: null})
		this.setState({myfilter: formData})

		this.loadData(formData);
	};

	callbackDesign = (formData) => {

		console.log("01 ------ formdata", formData);

		if (formData === "gray") this.setState({
			mydesigngray: true,
			mydesigncolor: false,
			mysegmentfill: true,
			mydesign: formData
		});

		if (formData === "color") this.setState({
			mydesigngray: false,
			mydesigncolor: true,
			mysegmentfill: false,
			mydesign: formData
		});

		if (formData === "opaque") this.setState({
			mydesigngray: false,
			mydesigncolor: true,
			mysegmentfill: true,
			mydesign: formData
		});

	};

	callbackColor = (formData) => {
		this.setState({mycolor: formData})
	};

	callbackNetColor = (formData) => {
		this.setState({mynetcolor: formData})
	};

	callbackSegment = (formData) => {
		this.setState({mysegmentfill: formData})
	};

	callbackSwitch = (id, checked) => {
		console.log(id + " " + checked);
		this.setState({[id]: checked})
	};

	callbackSearch = (formData) => {
		this.setState({mysearch: formData})
	};

	callbackToggle = (formData) => {
		this.setState({sunburstFirst: this.state.sunburstFirst === true ? false : true })
	};

	callbackToggle1 = () => {
		this.setState({restartforce: this.state.restartforce === true ? false : true })
	};

	callbackToggle2 = () => {
		this.setState({sunburstFirst: this.state.sunburstFirst === true ? false : true })
//		this.setState({restartforce: this.state.restartforce === true ? false : true })
	};

	callbackHelp = () => {
		this.handleHelpClick();
	};

	callbackSelectedImage = (imageId) => {
		this.setState({ selectedImage: imageId });
	};


	onSelect(event) { console.log("event"+event); }

	handleClick() {
		this.state.isToggleSidepanelOn ? document.getElementById("Sidepanel").style.width = "450px"
										: document.getElementById("Sidepanel").style.width = "0";
		this.setState(state => ({
			isToggleSidepanelOn: !state.isToggleSidepanelOn
		}));
	}

	handleHelpClick() {

		let isMo = this.state.isMobile;
		let sel = document.getElementById("Helppanel");

		if (!this.state.isToggleHelppanelOn) {
			sel.style.height = isMo ? "220px" : "220px"
			sel.style.width = isMo ? "340px" : "460px"
			sel.style.left = isMo ? "10px" : "37px"
			sel.style.visibility = "visible"
			sel.style.fontSize = isMo ? "1em" : "1.2em"
		}
		else {
			sel.style.height = "0";
			sel.style.width = "0";
			sel.style.visibility = "hidden"
		}
		this.setState(state => ({
			isToggleHelppanelOn: !state.isToggleHelppanelOn
		}));
	}

	changebreadcrumb() {
		var breadcrumbText = this.state.selectedText === null ? this.state.currData.name : this.state.selectedText;

		// google analytics selection
		let text = _.replace(breadcrumbText, /\[(.+?)\]/g, "");
		text = _.replace(text,new RegExp(' \&rarr; ','g'),'_');
		text = "selected: "+text;
//		ReactGA.event({
//			category: 'Vis',
//			action: text
//		});

		let elem = _.split(breadcrumbText, "[");
//		let ele = _.split(elem[0], "&rarr; ");
		let ele = _.split(elem[0], "&rarr; ");
//		let breadcrumbOut = "Auswahl: " + _.join(ele, "&rarr; ");
		let breadcrumbOut = "Auswahl: " + _.join(ele, "&rarr; ");
		this.setState({breadcrumb: breadcrumbOut})
	}


	loadData(id) {
//		console.log(process.env.REACT_APP_ROOT_PATH);
//		console.log(process.env.REACT_APP_TEST);
//		console.log("----> loading data record # "+id);

		let myurl = process.env.REACT_APP_ROOT_PATH+'/api/rest/cachedtrees/';
		fetch(myurl)
			.then(response => {
				return response.json();
			})
			.then(result => {
			const rndNum = Math.floor(Math.random() * Math.floor(100));
			const firstrecord = JSON.parse(result[id]['tree']);
			this.setState((currRecord, currData) => ({
				currRecord: result,
				currData: firstrecord,
				currRandom: rndNum
			}));
		});
		return;
	}

	fetchExternalHTML(fileName) {
		let myurl = process.env.REACT_APP_ROOT_PATH+'/api/rest/'+fileName;

		console.log(myurl);

		fetch(myurl)
			.then(response => {
				return response.json();
				})
			.then (result => {
				if (fileName === 'jsonfooter') {
				    this.setState({ extHTMLfooter: result })
				}
				else if (fileName === 'jsonheader') {
					this.setState({ extHTMLheader: result })
				}
				else {
					this.setState(prevState => ({ extHTML: [...prevState.extHTML, ...[result] ]}))
				}
			});
		return;
	}

	togglefont() {
		if (this.state.myfont) {
			this.div_fontstyle = {
				fontFamily: "abel",
			};
		} else {
			this.div_fontstyle = {
				fontFamily: "source_sans_proregular",
			};
		}
		if (this.state.myversalien) {
			this.div_versalien = {
				textTransform: 'uppercase',
			};
		}
		else {
			this.div_versalien = {
				textTransform: 'none',
				};
			}

		this.setState({myfont: this.state.myfont});
		this.setState({myversalien: this.state.myversalien});

//		this.setState({myfont: this.state.myfont, myversalien: this.state.myversalien});
		this.setState({myfont: this.state.myfont, myversalien: this.state.myversalien});
	}

	updateDimensions = () => {
		let device = rdd.deviceDetect();
		console.log("elem: ismobile", device);

		let isMobile = window.innerWidth < 500 ? true : false;

		this.setState({
			isMobile: isMobile,
			device: device,
			width: window.innerWidth,
			height: window.innerHeight
		});
	};


	componentDidMount() {
		this.l("----------> app: mounts");

//		ReactGA.pageview(window.location.pathname + window.location.search);
//		ReactGA.pageview("visualisierung.landessammlungen-noe.at/" + window.location.pathname + window.location.search);

		//     XXXXX  activate next 2 lines
//		this.fetchExternalHTML('jsonfooter');
//		this.fetchExternalHTML('jsonheader');

		console.log("elem ismobile? ",rdd.isMobile);
		console.log("elem isIE? ",rdd.isIE);
		console.log("elem isEdge? ",rdd.isEdge);
		console.log("elem isChrome? ",rdd.isChrome);
		console.log("elem isFF? ",rdd.isFirefox);

		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);

		this.loadData(this.state.myfilter);

		console.log(this.state.extHTML);

		this.togglefont();

		setTimeout( function () {
//				ReactGA.event({
//					category: 'Vis',
//					action: 'Sunburst loaded'
//					});
				this.handleHelpClick();
				this.setState({sunburstfirstload: true})
				this.changebreadcrumb();
		}.bind(this),3000)


		// parse URL/query string parameters
		// ex: ?color=2 switches to colorscheme 2
		let parsed = qs.parse(window.location.search);
		this.setState({queryParams: parsed});

		if (parsed.color && parseInt(parsed.color) > 0 && parseInt(parsed.color) < 14) {
			console.log(10);
			this.setState({mycolor: parseInt(parsed.color-1)});
		}

		if (parsed.v && parseInt(parsed.v) > 0 && parseInt(parsed.v) < 3) {
//			ReactGA.event({
//				category: 'Vis',
//				action: 'Network loaded'
//			});
			let sunb = parseInt(parsed.v) === 2 ? false : true;
			setTimeout( () => {this.setState({sunburstFirst: sunb })},50);
		}
	}

//
//	shouldComponentUpdate (nextProps, nextState) {
//		const differentFilter = this.state.myfilter !== nextState.myfilter;
//		const differentData = this.state.currData !== nextState.currData;
//		return differentFilter || differentData;
//	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.l("----------> app: updating now");
//		console.log(this.state);

		// fire helppanel on firstload of force layout
		if (!this.state.forcefirstload && !this.state.sunburstFirst && this.state.sunburstfirstload && document.getElementById("Helppanel")) {
			// check if window is open
			if ( document.getElementById("Helppanel").style.height !== "0")
			{
				document.getElementById("Helppanel").style.height = "0";
				document.getElementById("Helppanel").style.width = "0";
				document.getElementById("Helppanel").style.visibility = "hidden"
				this.setState(state => ({isToggleHelppanelOn: false}));
				setTimeout(function(){
					this.handleHelpClick();
				}.bind(this),1500)
			}
			else {
				this.handleHelpClick();
			}
			this.setState({forcefirstload: true})
		}

		if (prevState.selectedText !== this.state.selectedText ) {
			this.changebreadcrumb()
		}

		if (prevState.myfont !== this.state.myfont ) {
			this.togglefont();
		}

		if (prevState.myversalien !== this.state.myversalien ) {
			this.togglefont();
		}

		if (prevState.restartforce !== this.state.restartforce) {
			this.forceUpdate();
		}

		this.l("----------> app updated");
	}


	render() {
		if (!this.state.currData) {
			return (
				<div>
					<Head
						toggleCallback2={this.callbackToggle2}
					/>
					<br></br>
					<br></br>
					<div> ... loading ...</div>
				</div>
			)
		}
			return (
				<div className="App" style={Object.assign({},this.div_versalien,this.div_fontstyle)}>
				<Row id="header1">
					<Col>
						<Head
							toggleCallback2={this.callbackToggle2}
						/>
						{/*<div id="wrapper" dangerouslySetInnerHTML={{ __html: this.state.extHTMLheader }} />*/}
						{/*<button className="openbtn fixed-bottom" onClick={this.handleClick}><i><FontAwesomeIcon icon={faEllipsisV}/></i></button>*/}
						<div id="Sidepanel" className="sidepanel fixed-bottom">
							<a href="#" className="closebtn" onClick={this.handleClick}>&times;</a>
							<Configurator
								queryParams={this.state.queryParams}
								stylevers={this.div_versalien}
								style={this.div_fontstyle}
								myfilter={this.state.myfilter}
								mysearch={this.state.mysearch}
								myfont={this.state.myfont}
								myversalien={this.state.myversalien}
								myorderchron={this.state.myorderchron}
								mysegmentfill={this.state.mysegmentfill}
								mydesign={this.state.mydesign}
								mydesigngray={this.state.mydesigngray}
								mydesignwhite={this.state.mydesignwhite}
								mydesigncolor={this.state.mydesigncolor}
								mycolor={this.state.mycolor}
								mycolordefault={this.state.mycolordefault}
								filterCallback={this.callbackFilter}
								designCallback={this.callbackDesign}
								colorCallback={this.callbackColor}
								colorNetCallback={this.callbackNetColor}
								segmentCallback={this.callbackSegment}
								searchCallback={this.callbackSearch}
								toggleCallback={this.callbackToggle}
								toggleCallback1={this.callbackToggle1}
								switchCallback={this.callbackSwitch}
							/>
							{/*	/!*<p>filter: {this.state.myfilter} </p>*!/*/}
							{/*	/!*<p>search: {this.state.mysearch} </p>*!/*/}
						</div>
						{/*<button className="openhelpbtn fixed-bottom" onClick={this.handleHelpClick}><i><FontAwesomeIcon icon={faInfo}/></i></button>*/}


						{ this.state.isMobile ?
							(
							<button className="openhelpbtn_m fixed-bottom" onClick={this.handleHelpClick}><img src={require("./img/help_m.svg")}/></button>
							)
						:
							(
							<button className="openhelpbtn_d fixed-bottom" onClick={this.handleHelpClick}><img src={require("./img/help_d.svg")}/></button>
							)

						}

						<div id="Helppanel" className="helppanel fixed-bottom">
							<a href="#" className="closehelpbtn" onClick={this.handleHelpClick}>&times;</a>
							<Help
								queryParams={this.state.queryParams}
								isMobile={this.state.isMobile}
								sunburstFirst={this.state.sunburstFirst}
								stylevers={this.div_versalien}
								style={this.div_fontstyle}
								myfont={this.state.myfont}
								myversalien={this.state.myversalien}
								helpCallback={this.callbackHelp}
							/>
						</div>
					</Col>
				</Row>
				<Row id="viz" className={this.state.isMobile ? "justify-content-center" : ""}>
					<Col id="leftcol" xs="12" sm="12" md="12" lg="8" xl="6" className="">
							<Row>
								<Col >
									<div id="breadcrumb1" className="breadcrumb1" style={Object.assign({},this.div_versalien,this.div_fontstyle)}>&nbsp;</div>
								</Col>
							</Row>
							<Row>
								<Col id="vizleft" >
									{ this.state.sunburstFirst ? (
										<Sunburst
											queryParams={this.state.queryParams}
											data={this.state.currData}
											width="680"
											height="680"
											isMobile={this.state.isMobile}
											stylevers={this.div_versalien}
											style={this.div_fontstyle}
											myversalien={this.state.myversalien}
											myfont={this.state.myfont}
											mysearch={this.state.mysearch}
											myfilter={this.state.myfilter}
											mycolor={this.state.mycolor}
											mydesign={this.state.mydesign}
											mydesigngray={this.state.mydesigngray}
											mydesigncolor={this.state.mydesigncolor}
											myorderchron={this.state.myorderchron}
											mysegmentfill={this.state.mysegmentfill}
											selectedImage={this.state.selectedImage}
											selectedCallback={this.callbackSelected}
											toggleCallback={this.callbackToggle}
											_debug={true}
										/>
									) : this.state.restartforce ? (
										<Force
											data={this.state.currData}
											width="680"
											height="680"
											stylevers={this.div_versalien}
											style={this.div_fontstyle}
											mysearch={this.state.mysearch}
											myfilter={this.state.myfilter}
											myfont={this.state.myfont}
											mynetcolor={this.state.mynetcolor}
											selectedImage={this.state.selectedImage}
											selectedCallback={this.callbackSelected}
											toggleCallback={this.callbackToggle}
											toggleCallback1={this.callbackToggle1}
											_debug={true}
										/>
									) : (
										<Empty
											data={this.state.currData}
											width="680"
											height="680"
											toggleCallback1={this.callbackToggle1}
										/>
									)
								}
								</Col>
							</Row>
						</Col>
					<Col id="rightcol"
					     className="offset-lg-8 offset-xl-6"
						>
						<Row>
						<Col >
								<div id="breadcrumb2" className="breadcrumb2" style={Object.assign({},this.div_versalien,this.div_fontstyle)}>
									<div className={'selection'} dangerouslySetInnerHTML={{__html: this.state.breadcrumb}} />
								</div>
							</Col>
						</Row>

						<Row>
							<Col id="vizright" >
								<GalleryGrid
									stylevers={this.div_versalien}
									style={this.div_fontstyle}
									selectedNode={this.state.selectedNode}
									mythumbsize={this.state.mythumbsize}
									selectionCallback={this.callbackSelectedImage}
									myfont={this.state.myfont}
									myversalien={this.state.myversalien}
									screenwidth={this.state.width}
									currRandom={this.state.currRandom}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
					{/* XXXXX activate next line */}
					{/*<div dangerouslySetInnerHTML={{ __html: this.state.extHTMLfooter }} />*/}


					<div id="cfooter" className="cfooter fixed-bottom">
						<div id="credits" className="credit text-center fixed-bottom">
							developed by&nbsp;
							<a href="https://mindfactor.at" target='_blank' rel="noopener noreferrer">mindfactor</a>
							&nbsp;&&nbsp;
							<a href="https://www.donau-uni.ac.at/de/universitaet/fakultaeten/bildung-kunst-architektur/departments/kunst-kulturwissenschaften.html"
							target='_blank' rel="noopener noreferrer">Donau Universität Krems</a>
						</div>
					</div>

			</div>
			)
	}
}

ReactDOM.render(
	<App/>,
	document.getElementById('root')
);
export default App
