import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


class Head extends React.Component {

	constructor (props) {
		super(props);

		this.state = {
			scope: "Header",
			extHTMLheader: null,
			extHTMLfooter: null,
			submitform: '',
			errormessage: ''
		}
	}
	
	l(string) {	console.log("%c"+string, "color: blue");}

	myaction = (e) => {
		if (e === "clicked") {
			this.props.toggleCallback2(e);
		}
	};

	fetchExternalHTML(fileName) {
		let myurl = process.env.REACT_APP_ROOT_PATH+'/api/rest/'+fileName;

		console.log(myurl);

		fetch(myurl)
			.then(response => {
				return response.json();
			})
			.then (result => {
				if (fileName === 'jsonfooter') {
					this.setState({ extHTMLfooter: result })
				}
				else if (fileName === 'jsonheader') {
					this.setState({ extHTMLheader: result })
				}
				else {
					this.setState(prevState => ({ extHTML: [...prevState.extHTML, ...[result] ]}))
				}
			});
		return;
	}


	componentDidMount() {
		this.l("-----------> head mounted");
		this.fetchExternalHTML('jsonheader');
		console.log(this.state);
	}

	componentDidUpdate() {
		this.l("-----------> head updated");
//		console.log(this.state);
	}


	render() {
		return (
			<div>
				<header id="header">
					<div className="inside">
						<div id="topnavi" className="topnavibalkenmitburger">
							<div id="topburger">
								<img src="https://landessammlungen-noe.at/files/navibilder/burger.png"/>
							</div>
							
							<div className="cockpit">
								<div className="cockpit-item cockpit-label">
									<span className="highlight">Landessammlungen</span>}Online
								</div>
								<div className="cockpit-item">
									<a href="#" onClick={(ev) => this.myaction("clicked", ev)} 
									   rel="external"
									   className="cockpit-nav cockpit-nav-1">
										<span>Alles auf einen Blick</span>
									</a>
								</div>
								<div className="cockpit-item">
									<a href="#" onClick={(ev) => this.myaction("clicked", ev)}
									   className="cockpit-nav cockpit-nav-2">
									</a>
								</div>

								<div className="cockpit-item">
									<a href="https://www.online.landessammlungen-noe.at/"
									   className="cockpit-nav cockpit-nav-3 active">
										<span>Die Sammlungen</span>
									</a>
								</div>
								<div className="cockpit-item cockpit-info">
									<img src="Cockpit/images/navi_info.png"
									     title="Die Landessammlungen Niederösterreich umfassen mit den vier Gebieten Natur, Archäologie, Kunst und Kulturgeschichte den musealen Bestand des Bundeslandes Niederösterreich. Die etwa 6 Millionen Objekte sollen in den Landessammlungen Online zugänglich gemacht werden. Vorerst wird eine ausgewählte Anzahl an Objekten abrufbar sein, die in Zukunft kontinuierlich erweitert wird."/>
								</div>
								<div className="clearfix"></div>
							</div>
							
						</div>

						<div id="wrapper" dangerouslySetInnerHTML={{__html: this.state.extHTMLheader}}/>
					</div>

				</header>
			</div>
		);
	}
}

export default Head
