import React, {Component} from 'react';
import * as d3 from "d3";
import colforce_svg from "./img/colforce.svg";
import inner_circle_svg from "./img/inner_circle.svg";
import _ from 'lodash';

//import array from "d3-interpolate/src/array";
import contextMenuFactory from 'd3-context-menu';

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

class Sunburst extends Component {

	constructor(props) {
		super(props)

		this.state = {
			scope: "sunburst",
			data: this.props.data,
			viewbox: "0 0 680 680",
			width: this.props.width,
			isMobile: this.props.isMobile,
//			isMobile: true,
			lastClicked: null,
			lastSelected: null,
			lastSelectedId: null,
			lastDepth: 0,
			delayduration: 2000,
			ini: {
				replacetext: true,            // 1 replace log text with abbrev, 2 do not replace th log text
				replacetext_def: [2, "true", "false"],
				wraptext: true,            // 1 replace log text with abbrev, 2 do not replace th log text
				wraptext_def: [2, "true", "false"],
				addtilde: ["Archäologie", "Naturkunde"]
			},
			mysearch: this.props.mysearch,
			myorderchron: this.props.myorderchron,
			mysegmentfill: this.props.mysegmentfill,
			mydesign: this.props.mydesign,
			mydesigngray: this.props.mydesigngray,
			mydesigncolor: this.props.mydesigncolor,
			mydesignwhite: false,
//		    myfont: this.props.myfont,
//		    myversalien: this.props.myversalien,
//	        myfilter: this.props.myfilter,
			opacity1: 0.9,
			opacity2: 0.7,
			segmentfillcolor: '#F0F0F0',
			selectioncolor: 'red',
			strokewidth: 0.5,
			selstrokewidth: 2.5,
			currcolorscheme: null,
			_debug: false,
			_log: console.log,
			_warn: console.warn,
		};

		this.drawChart = this.drawChart.bind(this);

		if (this.state.mydesignwhite) {
			this.state.selstrokewidth = 0.5;
		}

	}

	l(string) {
		console.log("%c" + string, "color: blue");
	}

	myaction = (e) => {
		if (e === "clicked") {
			console.log("toggle clicked");
			this.props.toggleCallback();
		}
	};

	segmentopacity = (d) => {
		return d.children ? 0.4 : 0.2;
	}

	getfillidx = (d) => {

		while (d.depth > 1) {
			d = d.parent;
		}

		let colidx = 0;

		if (d.data.name === "Archäologie") {
			colidx = 0
		}
		if (d.data.name === "Kulturgeschichte") {
			colidx = 1
		}
		if (d.data.name === "Naturkunde") {
			colidx = 2
		}
		if (d.data.name === "Kunstsammlung") {
			colidx = 3
		}

//			return color(colidx);
		return colidx;
	}

	labelVisible = (d) => {

		let isMo = this.state.isMobile;

		return d.y1 <= (isMo ? 2 : 3) && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.05;
	}

	arcVisible = (d) => {
		return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
	}

	segSize = (d) => {
		return (d.current.y1 - d.current.y0) * (d.current.x1 - d.current.x0);
	}

	cleansunburst = () => {
		const {strokewidth} = this.state;
		const {segmentfillcolor} = this.state;
		const {currcolorscheme} = this.state;

		const {mydesign} = this.state;
		const mydesignopaque = mydesign === "opaque";

		d3.selectAll("path[class*='visible']")
			.attr("class", d => this.arcVisible(d.current) ? "visibleSegments" : "invisibleSegments")
			.style("fill", d => this.arcVisible(d.current) ? mydesignopaque ? currcolorscheme.color[this.getfillidx(d)] : segmentfillcolor : "none")
			.style("fill-opacity", d => {
				if (this.arcVisible(d.current)) {
					let grayopacity = d.children ? 0.99 : 0.8;
					return mydesignopaque ? this.segmentopacity(d) : grayopacity;
				} else {
					return 0;
				}
			})
			.style("stroke", "white")
			.style("stroke-width", 0.5)
		;

		d3.selectAll("text[id^='t']")
			.attr("class", d => this.labelVisible(d.current) ? "visibleText" : "invisibleText")
			.style("fill", d => this.labelVisible(d.current) ? mydesignopaque ? "black" : currcolorscheme.color[this.getfillidx(d)] : "black");
	}


	componentDidMount() {
		this.l("----------> Sunburst: drawing now");

		this.drawChart();
		console.log(this.state);
	}

//	shouldComponentUpdate(nextProps, nextState) {
//		if (
//			this.state.lastClicked === nextState.lastClicked &&
//			this.state.lastDepth === nextState.lastDepth &&
//			this.state.lastSelected === nextState.lastSelected &&
//			this.state.lastSelectedId === nextState.lastSelectedId
//		) {
//			console.log("%c no update", "color: white; background-color: orange");
//
//			return false;
//		} else {
//			console.log("%c !!! now we make the update", "color: white; background-color: orange");
//			return true;
//		}
//	}


	componentDidUpdate(prevProps, prevState, snapshot) {
		this.l("---------> Sunburst: updating now");
//		console.log(this.props);
//		console.log(this.state);

		// set symbol in cockpit to sunburst-symbol
		d3.selectAll(".cockpit-nav-1").attr("class", "cockpit-nav cockpit-nav-1 active");
		d3.selectAll(".cockpit-nav-2").attr("class", "cockpit-nav cockpit-nav-2");
		d3.selectAll(".cockpit-nav-3").attr("class", "cockpit-nav cockpit-nav-3");

		d3.selectAll("#sprachwahl").style("visibility", "hidden");
		d3.selectAll("#sprachgroessen").style("visibility", "hidden");

		if (this.props.myorderchron !== prevProps.myorderchron) {
			// redraw chart
			d3.selectAll("g").remove();
//			d3.selectAll(".breadcrumb1").remove();
			d3.selectAll("circle").remove();
			d3.selectAll("#Layer_1").remove();
			d3.selectAll("#Layer_2").remove();
			d3.selectAll(".circletext").remove();
			setTimeout(() => {
				this.drawChart();
			}, 100)
		}

		if (this.props.mycolor !== prevProps.mycolor) {
			// redraw chart
			d3.selectAll("g").remove();
//			d3.selectAll(".breadcrumb1").remove();
			d3.selectAll("circle").remove();
			d3.selectAll("#Layer_1").remove();
			d3.selectAll("#Layer_2").remove();
			d3.selectAll(".circletext").remove();
			setTimeout(() => {
				this.drawChart();
			}, 100)
		}

		if (this.props.mydesign !== prevProps.mydesign) {
			console.log("RELOAD RELOAD RELOAD RELOAD RELOAD RELOAD RELOAD ");

			this.setState({
				mysegmentfill: this.props.mysegmentfill,
				mydesigngray: this.props.mydesigngray,
				mydesigncolor: this.props.mydesigncolor,
				mydesign: this.props.mydesign
			});

			// redraw chart
//			d3.selectAll("path").remove();
			d3.selectAll("g").remove();
//			d3.selectAll(".breadcrumb1").remove();
			d3.selectAll("circle").remove();
			d3.selectAll("#Layer_1").remove();
			d3.selectAll("#Layer_2").remove();
			d3.selectAll(".circletext").remove();
			setTimeout(() => {
				this.drawChart();
			}, 100)
		}


		if (prevProps.selectedImage !== this.props.selectedImage) {
			let selectedImage = this.props.selectedImage;

			const {strokewidth} = this.state;
			const {segmentfillcolor} = this.state;
			const {currcolorscheme} = this.state;

			const {mydesign} = this.state;
			const mydesignopaque = mydesign === "opaque";

//			console.log("01 -> mydesignopaque", mydesignopaque);
//			console.log("01 -> mydesign", mydesign);

			d3.selectAll("path[class*='visible']")
				.attr("class", d => this.arcVisible(d.current) ? "visibleSegments" : "invisibleSegments");

			d3.selectAll("text[id^='t']")
				.attr("class", d => this.labelVisible(d.current) ? "visibleText" : "invisibleText");

			const visibleSegments = d3.selectAll('.visibleSegments');
			const visibleTexts = d3.selectAll('.visibleText');

//			console.log(visibleTexts);

			if (selectedImage === 0) {

				console.log("01 -> selimage === 0");

				if (this.props.mysegmentfill) {
					// clear all existing selections on startup
					this.cleansunburst();
				} else {
					visibleSegments.style('stroke', 'white').style("stroke-width", strokewidth)
				}

			} else {
				// fetch & select
				let depth = this.state.lastDepth;
				let mypath = process.env.REACT_APP_ROOT_PATH + '/api/rest/cachedpath/id/' + selectedImage;
				fetch(mypath)
					.then(response => {
						return response.json();
					})
					.then(result => {
						if (result.length < 1) {
							return;
						}

//						let pathnodes = result[0]['path_nodes'].split("|");

						let arr = _.join(_.map(result, 'path_nodes'), "|").split("|");

						let pathnodes = _.uniq(_.compact(arr));

						let paths = pathnodes.filter(d => d !== "");
						paths.map(d => parseInt(d));

						if (this.props.mysegmentfill) {
							this.cleansunburst();

//							visibleSegments.style('fill', d => mydesignopaque ? this : segmentfillcolor).style("stroke-width", strokewidth)
//							visibleSegments.style('fill-opacity', d => mydesignopaque ? this.segmentopacity(d) : this);
//
							let selected = [];
							paths.map((d) => {
								visibleSegments.filter(function (t) {
									if (d === t.data.id) selected.push(parseInt(d));
									return d === t.data.id;
								})
									.style("fill", d => currcolorscheme.color[this.getfillidx(d)])
									.style("fill-opacity", mydesignopaque ? 0.99 : this)
									.style("stroke-width", d => {
											if (mydesignopaque) {
												return this.segSize(d) < 0.0125 ? 3 : 0.5;
											} else {
												return this.state.selstrokewidth
											}
										}
									)
									.style("stroke", d => this.segSize(d) < 0.05 ? currcolorscheme.color[this.getfillidx(d)] : "white");
								;
							})

//							d3.selectAll("text[id^='t']").style("fill", d => this.labelVisible(d.current) ? mydesignopaque ? "black" : currcolorscheme.color[this.getfillidx(d)] : "green");

							selected.map(function (d) {
								d3.selectAll("text#t" + (d)).style("fill", "white");
							});
						} else {
							visibleSegments.style('stroke', "white").style("stroke-width", strokewidth);
							paths.map((d) => {
								visibleSegments.filter(function (t) {
									return d === t.data.id;
								})
									.style("stroke", this.state.selectioncolor)
									.style("stroke-width", this.state.selstrokewidth)
								;
							})
						}
					});
			}
		}

		if (prevProps.myfont !== this.props.myfont || prevProps.myversalien !== this.props.myversalien) {
//			d3.select('#partitionSVG').style("font-size", 10.5 + (this.props.myfont ? 3 : 2) + (this.props.myversalien ? -2 : -1));

			let isMo = this.state.isMobile;

			d3.select('#partitionSVG').style("font-size", (isMo ? 1.5 : 1) * (0.65 + (this.props.myfont ? 0.187 : 0.125) + (this.props.myversalien ? -0.125 : -0.0625)) + "em");
			;
		}

//		console.log(this.state)
		// !!! C A U T I O N: use ONLY d3 code here, otherwise react will re-render the sunburst with default values
	}

	componentUnmount() {
		this.l("----------> Sunburst: unmounting now");
		this._destroy_svg();
	}

	_destroy_svg() {
		this.l("----------> Sunburst: _destroy_svg()");
		this.svg && this.svg.selectAll('*').remove();
		this.svg = null
	}


	drawChart() {

		// get some global functions
		const arcVisible = this.arcVisible;
		const labelVisible = this.labelVisible;
		const segSize = this.segSize;
		const getfillidx = this.getfillidx;
		const segmentopacity = this.segmentopacity;
		const cleansunburst = this.cleansunburst;

		const geti = (key) => this.state.ini[key];

		this.state._debug && this.state._log("------------> Sunburst: _drawChart()");
		if (!this.props.data) return;
//		console.log(this.props);
//		console.log("this.props");
//		console.log(this.state);
//		console.log("this.state");

		var oncounter = false;
		var onmenu = false;
		var onmenu1 = false;
		var onsunburst = false;
		var onvissunburst = false;
		var oncircle = false;
		var onbutton = false;
		var onsvg = false;

		const getlastclicked = () => {
			return this.state.lastClicked;
		}
		const getlastselected = () => {
			return this.state.lastSelectedId;
		}
		const getlastDepth = () => {
			return this.state.lastDepth;
		}
		const getfont = () => {
			return this.props.myfont;
		}
		const getversalien = () => {
			return this.props.myversalien;
		}

		const data = this.state.data;

		const chronology = this.props.myorderchron;

		const delayduration = this.state.delayduration;

		const numFormat = d3.format(",d");
		const format = (d) => _.replace(numFormat(d), /,/g, '.');   // hotfix for thousands

		const width = this.state.width;

		const isMo = this.state.isMobile;

		var rscale = d3.scaleLinear().domain([1, isMo ? 1.85 : 1.5, 2]).range([1, isMo ? 1.98 : 1.5, 2]);

		const radius = width / 6 * (isMo ? 2 : 1.25);    // 1.125
		const shrink = radius / 3 * 2;          // make inner radius smaller

		const arc = d3.arc()
			.startAngle(d => d.x0)
			.endAngle(d => d.x1)
			.padAngle(d => Math.min((d.x1 - d.x0) / 2, mydesignwhite ? 0.009 : 0.005)) //designwhite
			.padRadius(radius * 1.5)
			.innerRadius(d => radius * rscale((d.y0)) - shrink + 1 - 1)
			.outerRadius(d => Math.max(radius * rscale((d.y0)) - shrink, radius * rscale((d.y1)) - shrink - 1));


		var partition = data => {
			const root = d3.hierarchy(data)
				.sum(d => d.size)
				.sort((a, b) => {

//					// keep sunburts 1st level always in the same order
					if (a.parent.data.name === "Gesamtsammlung") {

						let array = [
							"Archäologie",
							"Kulturgeschichte",
							"Naturkunde",
							"Kunstsammlung",
						];

						return array.indexOf(a.data.name) - array.indexOf(b.data.name);
					}

					if (chronology) {
						if (a.parent.data.name === "Kunst" ||
							a.parent.data.name === "20. Jhdt." ||
							a.parent.data.name === "21. Jhdt." ||
							a.parent.data.name === "Karikatur" ||
							a.parent.data.name === "Literatur"
						) {
							return b.data.name - a.data.name;
						}

						let arr = [
							"Paläolithikum/Mesolithikum",
							"Neolithikum",
							"Bronzezeit",
							"Eisenzeit",
							"Römische Kaiserzeit",
							"Völkerwanderungszeit",
							"Frühmittelalter",
							"Mittelalter",
							"Neuzeit",
							"Ohne Datierung"
						];

						if (a.parent.data.name === "Urgeschichte und Historische Archäologie") {
							let ai = arr.reduce((d, i) => {
								return a.data.name === d ? i : null
							});
							let bi = arr.reduce((d, i) => {
								return b.data.name === d ? i : null
							});
							return ai - bi;
						}

					}

					return b.value - a.value
					//				return b.data.name - a.data.name
				});
			return d3.partition()
				.size([2 * Math.PI, root.height + 1])(root);
		};

		const root = partition(data);

//		const color = d3.scaleOrdinal().range(d3.quantize(d3.interpolateRainbow, data.children.length + 1));

		const colorSchemeArr =
			[
				{
					color: ['rgb(240,236,226)', 'rgb(236, 223, 200)', 'rgb(236, 179, 144)', 'rgb(223, 120, 79)'],
					fontcolor1: [1, 1, 1, 0],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.9, 0.9, 0.9, 0.9],
//					opacity1: [0.5, 0.5, 0.5, 0.5],
					opacity2: [0.7, 0.7, 0.7, 0.7],
//					opacity2: [0.1, 0.1, 0.1, 0.1],
					stroke: 'rgb(179,6,6)',
					strokeneigh: 'rgb(179,6,6)'
				},
				{
					color: ['rgb(232,234,211)', 'rgb(241,248,100)', 'rgb(188,235,60)', 'rgb(124,189,60)'],
					fontcolor1: [1, 1, 1, 1],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.8, 0.8, 0.8, 0.8],
					opacity2: [0.6, 0.6, 0.6, 0.6],
					stroke: 'rgb(12,37,125)',
					strokeneigh: 'rgb(12,37,125)'
				},
				{
					color: ['rgb(173,105,137)', 'rgb(255, 156, 113)', 'rgb(251, 212, 109)', 'rgb(232, 234, 211)'],
					fontcolor1: [0, 1, 1, 1],
					fontcolor2: [0, 1, 1, 1],
					opacity1: [0.6, 0.7, 0.7, 0.7],
					opacity2: [0.4, 0.4, 0.4, 0.4],
					stroke: 'rgb(179,6,6)',
					strokeneigh: 'rgb(179,6,6)'
				},
				{
					color: ['rgb(110,64,170)', 'rgb(255, 94, 99)', 'rgb(175, 240, 91)', 'rgb(26, 199, 194)'],
					fontcolor1: [1, 1, 1, 1],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.6, 0.6, 0.6, 0.6],
					opacity2: [0.4, 0.4, 0.4, 0.4],
					stroke: 'rgb(255,0,170)',
					strokeneigh: 'rgb(0,0,0)'
				},
				{
					color: ['rgb(110,64,170)', 'rgb(255, 94, 99)', 'rgb(175, 240, 91)', 'rgb(26, 199, 194)'],
					fontcolor1: [0, 1, 1, 1],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.9, 0.9, 0.9, 0.9],
					opacity2: [0.6, 0.6, 0.6, 0.6],
					stroke: 'rgb(255,72,0)',
					strokeneigh: 'rgb(255,72,0)'
				},
				{
					color: ['#9bb899', '#fcceaa', '#f5827d', '#ea4961'],
					fontcolor1: [1, 1, 1, 0],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.99, 0.99, 0.99, 0.99],
					opacity2: [0.8, 0.8, 0.8, 0.8],
					stroke: 'rgb(255,72,0)',
					strokeneigh: 'rgb(255,72,0)'
				},
				{
					color: ['#d9f9f4', '#9cd9de', '#86c1d4', '#5a92af'],
					fontcolor1: [1, 1, 1, 0],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.99, 0.99, 0.99, 0.99],
					opacity2: [0.8, 0.8, 0.8, 0.8],
					stroke: 'rgb(255,72,0)',
					strokeneigh: 'rgb(255,72,0)'
				},
				{
					color: ['#cae8d5', '#84a9ac', '#3b6978', '#204051'],
					fontcolor1: [1, 1, 0, 0],
					fontcolor2: [1, 1, 0, 0],
					opacity1: [0.99, 0.99, 0.99, 0.99],
					opacity2: [0.8, 0.8, 0.8, 0.8],
					stroke: 'rgb(255,0,170)',
					strokeneigh: 'rgb(255,0,170)'
				},
				{
					color: ['#d7fbe8', '#9df3c4', '#62d2a2', '#1fab89'],
					fontcolor1: [1, 1, 1, 0],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.99, 0.99, 0.99, 0.99],
					opacity2: [0.8, 0.8, 0.8, 0.8],
					stroke: 'rgb(255,72,0)',
					strokeneigh: 'rgb(255,72,0)'
				},
				{
					color: ['#f67280', '#c06c84', '#6c5b7b', '#355c7d'],
					fontcolor1: [0, 0, 0, 0],
					fontcolor2: [0, 0, 0, 0],
					opacity1: [0.9, 0.99, 0.99, 0.99],
					opacity2: [0.8, 0.8, 0.8, 0.8],
					stroke: '#f0283f',
					strokeneigh: '#f0283f'
				},
				//#11, default, also used for designwhite
				{
//				color: ['#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
//					color: ['#E4A100', '#BC0A80', '#849866', '#009AB0'],
//					color: ['#CC9900', '#CC0099', '#99CC99', '#009999'],
					color: ['#CC9900', '#CC0099', '#77B877', '#009999'],
					fontcolor1: [0, 0, 0, 0],
					fontcolor2: [0, 0, 0, 0],
					opacity1: [0.99, 0.99, 0.99, 0.99],
					opacity2: [0.8, 0.8, 0.8, 0.8],
					stroke: '#b6b6b6',
					strokeneigh: '#f0283f'
				},
				// #12
				{
					//				color: ['#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
//					color: ['#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
//					color: ['#CC9900', '#CC0099', '#99CC99', '#009999'],
					color: ['#CC9900', '#CC0099', '#99CC99', '#009999'],
//					color: ['#CC9900', '#CC0099', '#77B877', '#009999'],
					fontcolor1: [1, 1, 1, 1],
					fontcolor2: [1, 1, 1, 1],
					opacity1: [0.5, 0.4, 0.7, 0.5],
					opacity2: [0.3, 0.3, 0.5, 0.3],
					stroke: '#363636',
					stroke: '#ff5100',
					stroke: '#131313',
					stroke: '#19214a',
					stroke: '#00555f',
					stroke: '#5c5c5c',
					stroke: '#FF00AA',
					stroke: '#aa0077',
					strokeneigh: '#FF00AA'
				},
				// #13
				{
//				color: ['#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
					color: ['#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
//					color: ['#CC9900', '#CC0099', '#99CC99', '#009999'],
					fontcolor1: [0, 0, 0, 0],
					fontcolor2: [0, 0, 0, 0],
					opacity1: [0.6, 0.5, 0.8, 0.6],
					opacity2: [0.3, 0.3, 0.5, 0.5],
					stroke: '#363636',
					stroke: '#ff5100',
					stroke: '#131313',
					stroke: '#19214a',
					stroke: '#5c5c5c',
					stroke: '#00555f',
					strokeneigh: '#f0283f'
				},
				//#14, default, mit original grün
				{
//				color: ['#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
//					color: ['#E4A100', '#BC0A80', '#849866', '#009AB0'],
//					color: ['#CC9900', '#CC0099', '#99CC99', '#009999'],
					color: ['#CC9900', '#CC0099', '#99CC99', '#009999'],
					fontcolor1: [0, 0, 0, 0],
					fontcolor2: [0, 0, 0, 0],
					opacity1: [0.999, 0.999, 0.999, 0.999],
					opacity2: [0.8, 0.8, 0.8, 0.8],
					stroke: '#b6b6b6',
					strokeneigh: '#f0283f'
				},
			];

		const {selstrokewidth: selstrokewidth} = this.state;
		const {strokewidth: strokewidth} = this.state;

		const mysegmentfill = this.props.mysegmentfill;

		const {mydesignwhite} = this.state;
		const {mydesigngray} = this.state;

		const mydesignopaque = this.state.mydesign === "opaque" ? true : false;

		const segmentfillcolor = (d) => {
			let f = null;

			let mydesign = this.state.mydesign;

			if (mysegmentfill && mydesignopaque) mydesign = 'opaque';
			if (mysegmentfill && mydesigngray) mydesign = 'gray';
			if (mydesignwhite) mydesign = 'white';

			if (mydesign === 'white') {
				f = '#FFF';
			}
			;
//			if (mydesign === 'gray') { f = '#dfdfdf';};
			if (mydesign === 'gray') {
				f = this.state.segmentfillcolor;
			}
			;
			if (mydesign === 'color') {
				f = colorscheme.color[getfillidx(d)];
			}
			;
			if (mydesign === 'opaque') {
				f = colorscheme.color[getfillidx(d)];
			}
			;

			return f;
		}

		console.log("mysegmentfill");
		console.log(mysegmentfill);

		var colorscheme = [];

		if (mysegmentfill) {
			colorscheme = this.props.queryParams ? colorSchemeArr[this.props.mycolor] : colorSchemeArr[10];
		} else {
			colorscheme = colorSchemeArr[this.props.mycolor];
		}

		this.setState({currcolorscheme: colorscheme});

		let currColor = colorscheme.color.map(d => d);

		var color = d3.scaleOrdinal().domain(data).range(currColor);

		const selectioncolor = colorscheme.stroke;
		const selectioncolorneigh = colorscheme.strokeneigh;
		this.setState({selectioncolor: selectioncolor});

		root.each(d => d.current = d);
		console.log(root);

		var svgpos = [];
		var pathpos = [];

		var div = d3.select(".breadcrumb1")
			.style("opacity", 0);

		const svg = d3.select('#partitionSVG')
			.style("width", isIE ? null : "100%")
			.style("height", isIE ? null : "100%")
			//			.style("font-size", 10.5 + (this.props.myfont ? 3 : 2) + (this.props.myversalien ? -2 : -1))
			.style("font-size", (isMo ? 1.7 : 1) * (0.65 + (this.props.myfont ? 0.187 : 0.125) + (this.props.myversalien ? -0.125 : -0.0625)) + "em")
			.on("mouseover", d => onsvg = true)
			.on("mouseout", d => onsvg = false)
			.on('mousemove', function () {
				svgpos[0] = d3.event.pageX;
				svgpos[1] = d3.event.pageY;
				setTimeout(() => {
					if (svgpos[0] === pathpos[0] && svgpos[1] === svgpos[1]) {
						console.log("elem_inside", this.state);

					} else {
						console.log("elem_ouside");
						// clear all existing selections on startup
						path.filter(t => t.data.id === getlastselected())
							.style("fill", d => mysegmentfill ? segmentfillcolor(d) : colorscheme.color[getfillidx(d)])
							.style("fill-opacity", d => mydesignopaque ? segmentopacity(d) : this)
							.style("stroke", "white");

						if (mydesignopaque) {
							d3.selectAll('.visibleSegments').style("fill-opacity", d => segmentopacity(d));
							d3.selectAll('.visibleText').style("fill", "black");
						}

					}
				}, 50)
			})
		;

//		svg.exit().remove();

		const g = svg.append("g")
			.attr("transform", `translate(${width / 2},${width / 2})`);

		console.log(svg);

		let selneighb = [];
		let neighb = [];
		let allneighb = [];

		const shorttext = (d) => {
			let parts = d.split("&rarr; ");
			let textout = textreplace(parts[parts.length - 1]);
			return "... " + parts[parts.length - 2] + " &rarr; <b>" + textout + "</b>"
		};

		// context menu function to generate selectable list for mouse over
		var menu = function (d) {

			mysegmentfill ? g.selectAll("path")
					.style('fill', d => segmentfillcolor(d))
					.style('stroke-width', strokewidth)
					.style('fill-opacity', d => mydesignopaque && arcVisible(d.current) > 0 ? segmentopacity(d) : this)
				: g.selectAll("path").style('stroke', 'white').style('stroke-width', strokewidth);

			d3.selectAll("svg#Layer_2").selectAll("path").style("stroke", "#939598");

			d3.selectAll(".d3-context-menu")
				.style("font-family", getfont() ? "abel" : "source_sans_proregular")
				.style("text-transform", getversalien() ? "uppercase" : "none");

			menu = [];
			if (segSize(d) < 0.05) {
				selneighb = g.selectAll("path")
					.filter(function (t) {
						return arcVisible(d.current) > 0
						&& t.value > 0
						&& t.depth === d.depth
						&& t.parent === d.parent
						&& segSize(t) < 0.05
							? t : null;
					})

				allneighb = g.selectAll("path")
					.filter(function (t) {
						return arcVisible(d.current) > 0
						&& t.value > 0
						&& t.depth === d.depth
						&& t.parent === d.parent
							? t : null;
					});

//					console.log(allneighb);

				if (arcVisible(d.current) === false) {
					console.log("arc not visible");
					return menu;
				}

				// get neighbour nodes
				let neighbours = [];
				selneighb.selectAll(function (d, i, g) {
					neighbours.push(d3.select(this).datum());
				});

				//check if true neighhbour
				neighb = checkneighbourhood(selneighb, d, allneighb);

				mysegmentfill
					? neighb
						.style('fill', d => mydesignopaque ? segmentfillcolor(d) : colorscheme.color[getfillidx(d)])
						//						.style('fill', d => colorscheme.color[getfillidx(d)])
						.style('fill-opacity', mydesignopaque ? 0.99 : this)
						.style("stroke-width", 0.1)
					: neighb.style('stroke', selectioncolorneigh).style("stroke-width", selstrokewidth);

				neighb.selectAll(function (d, i, g) {
					menu.push(new item(shorttext(d3.select(this).text())));
				});
				d3.selectAll(".invisibleSegments").style("stroke-width", 0);
			} else {
				// show select, set selection colors here, also avoid selection for hidden arcs

				var rscale1 = d3.scaleLinear().domain([0, 1.8, 2]).range([0, 1.98, 2]);
//				console.log("elem d.y0, d.y1 --->", d.y0, d.y1, rscale1(d.y0));

				mysegmentfill
					? d3.select(this).style('fill', d => colorscheme.color[getfillidx(d)])
						.style("fill-opacity", d => arcVisible(d.current) > 0 && mydesignopaque ? 0.99 : this)
					: d3.select(this).style('stroke', d => arcVisible(d.current) > 0 ? selectioncolor : 'white');

				// also set the fill color
				if (mysegmentfill) {

					// reset all selection text
					if (mydesignopaque) {
						d3.selectAll(".visibleText").style("fill", "black");
					}

					let currid = d3.select(this).datum().data.id;
					let idx = getfillidx(d);
					let fontcolor = "black";

					if (d.children) {
						fontcolor = colorscheme.fontcolor1[idx] ? "black" : "white";
					} else {
						fontcolor = colorscheme.fontcolor2[idx] ? "black" : "white";
					}

					d3.selectAll("text#t" + (currid)).style("fill", fontcolor)
				}

				d3.selectAll(".invisibleSegments").style("stroke-width", 0);
				d3.select(this).style('stroke-width', d => arcVisible(d.current) > 0 ? selstrokewidth : strokewidth)
//					d3.select(this).raise();
			}
			return menu;
		};

		var timer = 0;

		const path = g.append("g")
			.selectAll("path")
			.data(root.descendants().slice(1))
			.join("path")
			.attr("class", d => arcVisible(d.current) ? "visibleSegments" : "invisibleSegments")
			.attr("fill", d => {
				while (d.depth > 1) {
					d = d.parent;
				}
				return mysegmentfill ? segmentfillcolor(d) : color(d.data.name);
			})
			.attr("stroke-width", d => arcVisible(d) ? strokewidth : 0)
			.attr("stroke", d => mydesignwhite ? arcVisible(d) ? "gray" : "white" : "white")  //designwhite
			.attr("fill-opacity", d => {
				if (mydesignopaque && arcVisible(d)) return segmentopacity(d);
				let cur = g.selectAll("path").filter(t => t.data.id === d.current.data.id);
				let idx = _.findIndex(colorscheme.color, new String(cur.attr("fill")));
				idx = mysegmentfill ? getfillidx(d) : idx;
				return arcVisible(d.current) ? (d.children ? colorscheme.opacity1[idx] : colorscheme.opacity2[idx]) : 0
			})
			.on('mouseenter', contextMenuFactory(menu, {

				onOpen: function (d) {
					onsunburst = true;
					onvissunburst = arcVisible(d) ? true : false;

					// fire tooltips for single selection, hide otherwise
					if (menu.length === 0) {

						const _self = d3.select(this);
						addDelay(_self);

						d3.select(".d3-context-menu").style("visibility", "hidden");

						//check if it is visible
						if (_self.attr("fill-opacity") > 0) {
							updateSelected(_self);
						} else {
							timer.stop();
						}
						return addTooltip(_self);
					} else {
						// handle selection list (context-menu) here
						var cm = d3.select(".d3-context-menu").selectAll("li");

						cm.on("mouseover", (d, i, g) => {
							onmenu = true;
							// select corresponding arc
							const selectedArc = neighb.filter((t, l) => l === i);
							addDelay(selectedArc);
							updateSelected(selectedArc);
							addTooltip(selectedArc);
							//activate spinner
							d3.select(".d3-context-menu").selectAll("li")
								.filter((t, l) => l === i)
								.style("cursor", () => selectedArc.datum().data.children ? "pointer" : "default")
								.selectAll(".spinner").style("visibility", "visible");
						})
							.on("mouseout", () => {
								onmenu = false;
								timer.stop();
								// deactivate spinner
								d3.select(".d3-context-menu").selectAll("li").selectAll(".spinner").style("visibility", "hidden");
							});
					}
				},

				position: (d, i) => {

					var doc = document.documentElement;
					var pageWidth = window.innerWidth || doc.clientWidth;
					var pageHeight = window.innerHeight || doc.clientHeight;


					var corrheight = d3.event.pageY > pageHeight / 2 ? +18 : -18;
					var corrwidth = d3.event.pageX > pageWidth / 2 ? +18 : -18;


					if (this.props.isMobile) {
						console.log("_pos: ", pageHeight, pageWidth);
						console.log("_pos event: ", d3.event.pageY, d3.event.pageX);
						console.log("_pos_corr: ", corrheight, corrwidth);
						return {
							bottom: 380,
							top: 40,
							left: 40,
							right: 340
						}
					}


					return {
						bottom: d3.event.pageY - corrheight,
						top: d3.event.pageY + corrheight,
						left: d3.event.pageX + corrwidth,
						right: d3.event.pageX + corrwidth
					}
				}

			}))
			.on("mouseleave", function (d) {
				onsunburst = false;
				onvissunburst = arcVisible(d) ? true : false;

				d3.selectAll(".invisibleSegments").style("stroke-width", 0);
				removeTooltip();
				const that = this;
				const curr_d = d;
				try {
					timer.stop()
				} catch (error) {
					console.log("timer: " + error);
				}
				d3.timeout(function () {
					// keep selection if context menu is shown
					const contMenu = d3.select(".d3-context-menu");
					if (contMenu.length > 0) {
						if (contMenu.style("visibility") === "hidden" || contMenu.style("opacity") === 0) {
							mysegmentfill ? g.selectAll("path")
									.style('fill', d => segmentfillcolor(d))
									.style('fill-opacity', d => mydesignopaque ? segmentopacity(d) : this)
									.style("stroke-width", strokewidth)
								: g.selectAll("path").style('stroke', 'white').style("stroke-width", strokewidth);
						}
					} else {
						// handle selections outside
						if (mysegmentfill) {
							g.select(this).style('fill', d => segmentfillcolor(d)).style('stroke-width', strokewidth);
							g.select("path")
								.filter((t) => t.data.id === d3.select(that).datum().data.id)
								.style('fill-opacity', d => mydesignopaque ? segmentopacity(d) : this)
								.style('fill', d => segmentfillcolor(d));

							d3.selectAll("text#t" + (d3.select(that).datum().data.id))
								.style("fill", mydesignopaque ? "black" : colorscheme.color[getfillidx(d)]);
						} else {
//							d3.selectAll(".invisibleSegments").style("stroke-width", 0);
							g.select(this).style('stroke', 'white').style('stroke-width', strokewidth);
						}
						if (g.selectAll('path').length !== undefined) {
							mysegmentfill ? g.selectAll('path').filter(t => t.data.id === d.data.id)
									.style("fill", d => segmentfillcolor(d))
									.style('fill-opacity', d => mydesignopaque ? segmentopacity(d) : this)
									.style("stroke-width", strokewidth)
								: g.selectAll('path').filter(t => t.data.id === d.data.id)
									.style("stroke", "white")
									.style("stroke-width", strokewidth)
						}
					}
				}, 100);
			})
			.on('mousemove', function () {
				pathpos[0] = d3.event.pageX;
				pathpos[1] = d3.event.pageY;
			})
			.attr("d", d => arc(d.current));

		path.filter(d => d.children && arcVisible(d.current))
			.style("cursor", "pointer")
			.on("click", clicked.bind(this));

		path.append("label")
			.text(d => addLabel(d));

		const parent = g.append("circle")
			.attr("class", "centertitle")
			.datum(root)
			.attr("r", radius - shrink - 2)
			.attr("fill", "white")
			.attr("pointer-events", "all")
			.on("mouseover", rootSelected)
			.on("mouseout", rootUnselected)
			.on("click", clicked.bind(this));

		parent.append("label")
			.attr("class", "centertitle")
			.text(d => addLabel(d));

		const label = g.append("g")
			.attr("pointer-events", "none")
			.attr("text-anchor", "middle")
			.style("user-select", "none")
			.selectAll("text")
			.data(root.descendants().slice(1))
			.join("text")
			.attr("class", d => labelVisible(d.current) ? "visibleText" : "invisibleText")
			.attr("id", (d) => "t" + d.current.data.id)
			.attr("fill-opacity", d => +labelVisible(d.current))
			.style("fill", function (d) {
				if (mydesignopaque) return "black";
				if (mysegmentfill) return colorscheme.color[getfillidx(d)];
				let cur = g.selectAll("path").filter(t => t.data.id === d.current.data.id);
				let idx = _.findIndex(colorscheme.color, new String(cur.attr("fill")));
				if (d.children) {
					return colorscheme.fontcolor1[idx] ? "black" : "white";
				} else {
					return colorscheme.fontcolor2[idx] ? "black" : "white";
				}
			})
			.attr("dy", "0.35em")
			.attr("transform", d => labelTransform(d.current))
			.text(d => d.data.name)
			//			.call(wrap, this.props.myversalien ? 150 : 140);
			.call(wrap, (isMo ? 1.5 : 1) * (this.props.myversalien ? 150 : 140));

//		const label2 = g.append("g").selectAll("text").call(wrap, 8);

		console.log("label: ");
		console.log(label);

		let togglebutton = d3.xml(colforce_svg)
			.then(data => {

				svg.node().append(data.documentElement);

				var img = svg.selectAll("svg#Layer_1");

				img.attr("x", width - 60)
					.attr("class", "svgbutton")
					.attr("y", 0)
					.attr("width", 50)
					.attr("height", 50)
					.style("cursor", "pointer")
					.attr("pointer-events", "none")
			});

		// circle below togglebutton to catch all selects
		svg.append("circle")
			.attr("class", "mycircle")
			.attr("r", 40)
			.attr("cx", width - 20)
			.attr("cy", 20)
			.attr("stroke", "none")
			.attr("fill", "none")
			.style("cursor", "pointer")
			.attr("pointer-events", "all")
			.on("mouseover", function () {
				onbutton = true;
				svg.selectAll("line").attr("stroke", "#F0283F");
				svg.selectAll(".svgbutton").selectAll("circle").attr("stroke", "#F0283F");
				tooltip1.style("visibility", "visible")
			})
			.on("mouseout", function () {
				onbutton = false;
				svg.selectAll("line").attr("stroke", "#939598");
				svg.selectAll(".svgbutton").selectAll("circle").attr("stroke", "#939598");
				tooltip1.style("visibility", "hidden")
			})
			.on("click", toggle.bind(this))

		svg.append("text")
			.attr("class", "circletext")
			.attr("dx", width / 2 - (isMo ? 70 : 40))
			.attr("dy", width / 2 + 5)
			.attr("font-size", 0.9 + "em")
			.attr("pointer-events", "none")
			.text("Gesamtsammlung");


		svg.append("text")
			.attr("class", "circletextback")
			.attr("visibility", "hidden")
			.attr("dx", width / 2 - (isMo ? 22 : 15))
			.attr("dy", width / 2 + (isMo ? 48 : 30))
			.attr("font-size", 0.9 + "em")
			.attr("pointer-events", "none")
			.text("zurück");

		var tooltip1 = svg.append("foreignObject")
			.style("visibility", "hidden")
			.attr("width", isMo ? 180 : 140)
			.attr("height", 70)
			.attr("x", width - (isMo ? 210 : 120))
			.attr("y", 40)
			.attr("pointer-events", "none")
			.append("xhtml:div")
			.style("font-size", "1em")
			.style("background-color", "white")
			.style("border", "solid")
			.style("border-color", "lightgrey")
			.style("border-width", "0.5px")
			.style("border-radius", "1px")
			.style("padding", "5px")
			.style("z-index", 1300)
			.html('Klicken Sie hier für eine andere Ansicht');

		// show all labels with linebreaks
//		console.log(label.select(".t2").filter((d, i, g) => {
//			console.log((d.data.name));
//		}));


		function wrap(text, width) {

			// use underscore to make an intentional line break

			if (!geti("wraptext")) return;

			text.each(function () {
				var text = d3.select(this),
					textreplaced = textreplace(text.text()),
					textsubbed = textreplaced.replace(/\//, "/ "),
					words = textsubbed.split(/\s+/).reverse(),
					word,
					line = [],
					lineNumber = 0,
					lineHeight = 1.1, // ems
					y = text.attr("y"),
					dy = parseFloat(text.attr("dy")),
					tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em").attr("class", "t1"),
					corr_up = -.7,
					corr_narrow = -.1
				;

				while (word = words.pop()) {
//					console.log(textsubbed, textsubbed.length);
					line.push(word);
					tspan.text(line.join(" "));
					if (textsubbed.length > 20) {
						if (tspan.node().getComputedTextLength() > width) {
							tspan.attr("dy", dy * corr_up + "em");  // move up a little bit
							line.pop();
							line = line.map(d => d.replace(/\_/, "")); // remove underscore from first line
							tspan.text(line.join(" "));
							line = [word];
							tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy * corr_narrow + "em").attr("class", "t2").text(word);
						}

					}
				}
			});
		}

		function textreplace(text) {

			if (!geti("replacetext")) return text;

			var textout = text;

			var subst = [
				{
					source: "Urgeschichte und Historische Archäologie",
					target: "Urgeschichte &_ _ Historische Archäologie"
				},
				{source: "Slg MSHM", target: "Mährisch-Schlesisches Heimatsmuseum"},
				{source: "Paläolithikum/Mesolithikum", target: "Paläo- & Mesolithikum"},
				{source: "Slg Schulmuseum Michelstetten", target: "Schulmuseum_ _ Michelstetten"},
				{source: "Dokument/Urkunde/Papierarbeit", target: "Dokumente/Urkunden"},
				{source: "Slg Hackl & Söhne, Weitra", target: "Hackl & Söhne, Weitra"},
				{source: "Slg Museumswirtshaus Hofbauer", target: "Museumswirt Hofbauer"},
				{source: "Slg Historische Wertpapiere", target: "Historische Wertpapiere"},
				{source: "Slg OFLAG XVII A Edelbach", target: "Offizierslager Edelbach"},
				{source: "Technik/Gewerbe/Handwerk", target: "Technik & Handwerk"},
				{source: "Handwerk/Industrie/Handel", target: "Handwerk & Industrie"},
				{source: "Öffentlichkeit und Gemeinwesen", target: "Gemeinwesen"},
				{source: "Freizeit/Unterhaltung/Genuss", target: "Unterhaltung/Genuss"},
				{source: "Puppen und Puppenzubehör", target: "Puppen & Puppenzubehör"},
				{source: "außereuropäisches Spielzeug", target: "außereurop. Spielzeug"},
				{source: "Sarkophage und Aschenkisten", target: "Sarkophage & Aschenkisten"},
				{source: "Slg Fam.Waissnix-Thalhof Reichenau", target: "Waissnix-Thalhof Reichenau"},
				{source: "Slg Bruckmeier-GH Lang Eichgraben", target: "Bruckmeier-Eichgraben"},
				{source: "Transport- und Vorratsgefäße", target: "Transport- & Vorratsgefäße"},
				{source: "Kerzenständer und Kandelaber", target: "Kerzenständer & Kandelaber"},
				{source: "Schleudern und Geschütze", target: "Schleudern & Geschütze"},
				{source: "selbstgemachtes Spielzeug", target: "selbstgemachtes Spielzeug"},
				{source: "Schanz- und Marschgepäck", target: "Schanz- & Marschgepäck"},
				{source: "Ehrenzeichen und Symbole", target: "Ehrenzeichen & Symbole"},
				{source: "Ansichtskartensammlung", target: "Ansichtskarten"},
				{source: "Öffentlichkeit und Gemeinwesen", target: "Gemeinwesen"}
			];

			subst.forEach((d) => {
				if (text === d.source) {
					textout = d.target
				}
			});
			textout = textout.replace(/Slg /, "");
			textout = textout.replace(/und /, "& ");
			return textout;
		}

		function addLabel(d) {
			// compute true size from subtree for non-leaf nodes, since sum uses rescaled size
			let subtree = d.copy().sum(f => f.truesize);
			let outsize = d.data.truesize ? d.data.truesize : subtree.value;
			let tilde = "", list = geti("addtilde");
			if (d.ancestors().find((e) => list.find((l) => e.data.name === l)) || d.descendants().find((e) => list.find((l) => e.data.name === l))) {
				tilde = "~"
			}
			return `${d.ancestors().map(d => d.data.name).reverse().join(" &rarr; ")}\n[${tilde}${format(outsize)}]`;
		}

		function rootSelected(d, i) {
			console.log("root selected");
			oncircle = true;
			if (mysegmentfill) {
				g.selectAll("text").style('fill', d => mydesignopaque ? "black" : colorscheme.color[getfillidx(d)]);
				g.selectAll("path")
					.style('fill-opacity', d => arcVisible(d.current) > 0 && mydesignopaque ? segmentopacity(d) : this)
					.style('fill', d => segmentfillcolor(d));
			} else {
				g.selectAll("path").style('stroke', 'white').style("stroke-width", strokewidth);
			}

			d3.selectAll("circle")
				.attr('visibility', 'default')
				.attr('cursor', 'pointer');
			d3.selectAll("svg#Layer_2").selectAll("path").style("stroke", selectioncolor);

			//			div.style('visibility', 'hidden');
			const _self = d3.select(this);
			_self.style('stroke', selectioncolor).style("stroke-width", 2);

			if (getlastDepth() < 1) {
				// next 2 lines fetch inner circle
				addDelay(_self);
				updateSelected(_self);
			}

			d3.selectAll(".invisibleSegments").style("stroke-width", 0);

			return addTooltip(_self);
		}

		function rootUnselected(d, i) {
			oncircle = false;
			timer.stop();
			console.log("root unselected");
			d3.select(this).style('stroke', 'white').style("stroke-width", d => arcVisible(d.current) > 0 ? strokewidth : 0);
			d3.selectAll("svg#Layer_2").selectAll("path").style("stroke", "#939598");
		}

		function toggle() {
			this.myaction("clicked");
		}

		function hideinnercircle() {

			console.log("elem: hide circle")

			d3.select(".circletext").attr("visibility", "visible");
			d3.select(".circletextback").attr("visibility", "hidden");
			parent.attr("class", "centertitle").attr("visibility", "hidden");
			d3.selectAll("svg#Layer_2").selectAll("path").attr("visibility", "hidden").transition().duration(500).style("opacity", 0);
		}

		function showinnercircle() {

			const isMo = getIsMobile();
			console.log("elem inner:", isMo);

			console.log("elem: show circle")
			d3.xml(inner_circle_svg)
				.then(data => {

					svg.node().append(data.documentElement);

					var img = svg.selectAll("svg#Layer_2");

					let sizer = isMo ? 30 : 20;

					img.attr("x", width / 2 - sizer)
						.attr("y", width / 2 - sizer)
						.attr("width", sizer * 2)
						.attr("height", sizer * 2)
						.attr("pointer-events", "none")
						.style('opacity', 0)
						.on("mouseover", function () {
							oncircle = true;
							img.selectAll("path").style("stroke", isMo ? "#939598" : selectioncolor).style("stroke-width", 2);
							d3.select(".centertitle").style("stroke", selectioncolor);
						})
						.on("mouseout", function () {
							oncircle = false;
							img.selectAll("path").style("stroke", "#939598").style("stroke-width", 2);
							d3.select(".centertitle").style("stroke", "#939598");
						});

					img.transition()
						.duration(3000)
						.style('opacity', 1);

				});

			parent.attr("class", "centertitle").attr("visibility", "visible");
			d3.selectAll("svg#Layer_2").selectAll("path").style("visibility", "visible");

			d3.select(".circletext").attr("visibility", getlastDepth() > 0 ? "hidden" : "visible");

			setTimeout(() => {
				d3.select(".circletextback").attr("visibility", getlastDepth() > 0 ? "visible" : "hidden");
			}, 2500)

		}

		function clicked(p) {

			if (!p.children) {
				return
			}

			console.log("elem: p", p);

			console.log("o02 -> lastdepth: ", this.state.lastDepth);
			console.log("o02 -> clickeddepth: ", p.depth);

			this.setState({lastClicked: p});
			this.setState({lastDepth: p.depth});

			parent.datum(p.parent || root);

			// format and update circle in center
			parent.style("cursor", () => this.state.lastDepth > 0 ? "pointer" : "default");
			parent.attr("class", "centertitle").text(d => addLabel(d));

			// update pointer arcs
			path.filter(d => d.children && arcVisible(p))
				.style("cursor", "pointer")
				.on("click", clicked.bind(this));


			root.each(d => d.target = {
				x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
				x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
				y0: Math.max(0, d.y0 - p.depth),
				y1: Math.max(0, d.y1 - p.depth)
			});

			const t = g.transition().duration(1200);

			if (mydesignopaque) {
				g.selectAll("path").style("fill-opacity", d => arcVisible(d.current) ? segmentopacity(d) : 0);
				g.selectAll("path").style("fill-opacity", d => arcVisible(d.target) ? segmentopacity(d) : this);
				g.selectAll("path").attr("fill-opacity", d => segmentopacity(d));
			}

			// Transition the data on all arcs, even the ones that aren’t visible,
			// so that if this transition is interrupted, entering arcs will start
			// the next transition from the desired position.
			path.transition(t)
				.tween("data", d => {
					const i = d3.interpolate(d.current, d.target);
					return t => d.current = i(t);
				})
				.filter(function (d) {
					return +this.getAttribute("fill-opacity") !== 0 || +this.getAttribute("fill-opacity") || arcVisible((d.target));
				})
				.attr("fill-opacity", d => {
					if (mydesignopaque) {
						return arcVisible(d.target) ? segmentopacity(d) : 0
					}
					let cur = g.selectAll("path").filter(t => t.data.id === d.data.id);
					let idx = _.findIndex(colorscheme.color, new String(cur.attr("fill")));
					idx = mysegmentfill ? getfillidx(d) : idx;
					return arcVisible(d.target) ? (d.children ? colorscheme.opacity1[idx] : colorscheme.opacity2[idx]) : 0
				})
				.style("fill-opacity", d => {
					if (mydesignopaque) {
						return arcVisible(d.target) ? segmentopacity(d) : 0
					}
				})
				// hotfix, sometimes very small arcs are rendered after zoomout
				.attr("stroke-width", d => arcVisible(d.target) * 1.0001)
				.attr("stroke", d => mydesignwhite ? arcVisible(d.target) ? "gray" : "white" : "white")  //designwhite
				.attrTween("d", d => () => arc(d.current));

			label.filter(function (d) {
				return +this.getAttribute("fill-opacity") || labelVisible(d.target);
			}).transition(t)
				.attr("fill-opacity", d => +labelVisible(d.target))
				.style("fill", function (d) {
					if (mydesignopaque) return "black";
					if (mysegmentfill) return colorscheme.color[getfillidx(d)];
					let cur = g.selectAll("path").filter(t => t.data.id === d.data.id);
					let idx = _.findIndex(colorscheme.color, new String(cur.attr("fill")));
					if (d.children) {
						return colorscheme.fontcolor1[idx] ? "black" : "white";
					} else {
						return colorscheme.fontcolor2[idx] ? "black" : "white";
					}
				})
				.attrTween("transform", d => () => labelTransform(d.current));

			hideinnercircle();
			this.state.lastDepth > 0 ? setTimeout(() => {
				showinnercircle()
			}, 200) : hideinnercircle();

			let sel = null;

			if (parseInt(p.data.id) === 1) {
				sel = d3.select("circle");
			} else {
				sel = path.filter(d => d.data.id === p.data.id);
			}

			if (!sel.empty()) {
				setTimeout(() => {
					fetchImagesNow(sel)
				}, 700)
			}

			g.select("path").filter(d => d.data.id === p.data.id).style("stroke-width", selstrokewidth);

			var point = d3.mouse(d3.selectAll("*").node()), coords = {x: point[0], y: point[1]};
//			console.log("elem"+coords.x, coords.y);

			// update selections after click
			setTimeout(function () {

				if (mydesignopaque) {
					path.attr("class", d => arcVisible(d.target) ? "visibleSegments" : "invisibleSegments");
					label.attr("class", d => labelVisible(d.target) ? "visibleText" : "invisibleText")

					let visible = d3.selectAll(".visibleSegments");
					let invisible = d3.selectAll(".invisibleSegments");

					invisible.style("fill-opacity", 0);
				} else {
					path.attr("class", d => arcVisible(d) ? "visibleSegments" : "invisibleSegments");
					label.attr("class", d => labelVisible(d) ? "visibleText" : "invisibleText")

					let currsel = d3.selectAll(".visibleSegments");
					currsel.style("fill", d => mysegmentfill ? segmentfillcolor(d) : colorscheme.color[getfillidx(d)])
						.style("stroke-width", strokewidth - 1); // hotfix, sometimes very small arcs are rendered after zoomout
					path.filter(d => d.data.id === p.data.id).style("stroke-width", selstrokewidth);
				}

				if (!mysegmentfill) {
					path.filter(d => d.data.id === getlastselected()).style("stroke-width", selstrokewidth);
				}

			}.bind(this), 800);

			if (mydesignopaque) {
				setTimeout(function () {

					path.attr("class", d => arcVisible(d) ? "visibleSegments" : "invisibleSegments");
					label.attr("class", d => labelVisible(d) ? "visibleText" : "invisibleText")

					g.selectAll("path").style("fill-opacity", d => arcVisible(d.target) ? segmentopacity(d) : 0);

					let lastselected = path.filter(d => d.data.id === getlastselected());

//					// if on lastselected, set opacity
					let sellabel = label.filter(function (d) {
						return arcVisible(d.target) && d3.style(d3.select(this).node(), 'fill') !== 'black'
					});

					if (!sellabel.empty()) {
						let sellabelid = sellabel.datum().data.id;
						path.filter(d => d.data.id === sellabelid).style("fill-opacity", .99);
					}

//					console.log("elem pos path/svg/coords", pathpos, svgpos, coords);
					if (svgpos[0] === pathpos[0] === coords[0] && svgpos[1] === svgpos[1] === coords[0]) {
						console.log("elem_inside", coords);
						lastselected.style("fill-opacity", d => arcVisible(d.target) ? .99 : this);
					}

				}.bind(this), 1600);

			}


		}

		function getstyle(sel, style) {
			return d3.style(sel.node(), style);
		}

		function labelTransform(d) {
			const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
			const y = (d.y0 + d.y1) / 2 * radius - shrink;
			return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
		}

//		function segSize(d) {
//			return (d.current.y1 - d.current.y0) * (d.current.x1 - d.current.x0);
//		}

		// add item to menu list
		function item(t) {
			const that = this;
			const spinner = " <span style=\"visibility: hidden\" class=\"spinner spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>";

			that.title = () => {

				return t + spinner;
			};

			// apply fake click event
			that.action = function (d, i) {
				updateSelected2(d);
				timer.stop();
				let current = d3.select(this);
				return d.children && !current.empty() ? current.on("click").apply(this, [d, i]) : null;
			};
		}

		function removeTooltip(sel) {

			// remove tooltip
//			div.transition()
//				.duration(200)
//				.style("opacity", 0.5);
////			addTooltip(sel);

			// select last level instead of removing tooltip
			if (getlastclicked()) {
				addTooltip(path.filter(t => t.data.id === getlastclicked().data.id));
			} else {
				addTooltip(path.filter(t => t.data.id === 0));
			}

		}

		function addTooltip(sel) {

			let rootCenterSelected = !sel.empty() && sel.classed("centertitle") && getlastDepth() < 1 ? true : false;

//			console.log("trigger: ---");
//			console.log(sel.empty() ? "trigger: empty" : "trigger: NOT empty");
////			console.log(sel.empty() ? "trigger: empty" : "trigger: NOT empty");
//			if(!sel.empty()) {
//				sel.attr("class") === "invisibleSegments" ? console.log("trigger: invisible") : console.log("trigger: NOT invidsible");
//				sel.attr("class") === "visibleSegments" ? console.log("trigger: visible") : console.log("trigger: NOT vidsible");
//				sel.attr("class") !== undefined ? console.log("trigger: class") : console.log("trigger: NO class");
//				sel.attr("class") !== undefined ? console.log("trigger: class" + sel.attr("class")) : console.log("trigger: NO class");
//			}

			if (!sel.empty() && sel.attr("class") === "invisibleSegments") {
				if (getlastclicked()) {
					sel = path.filter(t => t.data.id === getlastclicked().data.id);
				} else {
					sel = d3.select("circle");
				}
			}

			if (sel.empty()) {
				sel = d3.select("circle");
			}

			var labeltext;

			sel.selectAll(function (d) {
				//labeltext = d.data.name;
				labeltext = d3.select(this).text().valueOf();
			});

			div.transition()
				.duration(200)
				.style("opacity", 1);

//				div.style("visibility", rootCenterSelected ? "hidden" :"visible");
			div.style("visibility", "visible");

			updateVisible();

			labeltext = labeltext;

			// fixed position tooltips, now they are like breadcrumbs
			div.html((labeltext))
				.style("left", "0 px")
				.style("top", "0 px");

			div.style("font-family", getfont() ? "abel" : "source_sans_proregular")
				.style("text-transform", getversalien() ? "uppercase" : "none");


//				if (!rootCenterSelected) { addSpinner() }

			addSpinner();

		}

		const updateVisible = () => {
			d3.selectAll(".invisibleSegments").style("cursor", "default").on("click", null);
			d3.selectAll(".invisibleSegments").style("stroke-width", 0);
			d3.selectAll(".visibleSegments")
				.style("cursor", d => d.children ? "pointer" : "default")
				.on("click", clicked.bind(this));
		}

//		const delayduration = 4000;

		const addDelay = (_self) => {
			if (_self !== null) {
				timer = d3.interval((elapsed) => {
					activateSpinner();
					console.log(elapsed + " self: " + _self.datum().data.id + " stored: " + checkselected());
					if (elapsed > delayduration) {
						timer.stop();
						deactivateSpinner();
						console.log("%ctimer stopped now", "color: white; background-color: green");
						fetchImagesNow(_self);
					}
					;
				}, 500);
			}
		};

		const fetchImagesNow = (sel) => {
			console.log("sel");
			console.log(sel);
			let out = {
				id: sel.datum().data.id,
				text: sel.text()
			};
			return this.props.selectedCallback(out);
		}

		const addSpinner = () => {
			div.append("span")
				.attr("class", "spinner spinner-border spinner-border-sm")
				.style("visibility", "hidden");
		}

		const activateSpinner = () => {
			div.select("span").style("visibility", "visible");
		};

		const deactivateSpinner = () => {
			div.select("span").style("visibility", "hidden");
			d3.selectAll(".d3-context-menu").selectAll("span").style("visibility", "hidden");
		};

		const updateSelected = (sel) => {
			path.attr("class", d => arcVisible(d.current) ? "visibleSegments" : "invisibleSegments");
			return sel.attr("fill-opacity") > 0 || sel.classed("centertitle") ? this.setState({
				lastSelected: sel.datum(),
				lastSelectedId: sel.datum().data.id
			}) : null;
		};

		const updateSelected2 = (sel) => {
//			console.log(sel);
//			console.log(sel.data.id);
//			let out = {
//				id: sel.data.id,
//				text: sel
//			};
//			return this.props.selectedCallback(out);
//			return this.setState({lastSelected: sel, lastSelectedId: sel.data.id, lastClicked: sel});
//			return this.setState({lastClicked: sel});
			return null;
		};

		const checkselected = () => {
			if (this.state.lastSelectedId !== null) {
				return this.state.lastSelectedId;
			}
		}

		const getIsMobile = () => {
			return this.props.isMobile;
		}

		const checkneighbourhood = (items, target, all) => {

			let silblings = [];

			all.each(function (c) {
				var entry = {
					current: d3.select(this).text(),
					previous: d3.select(this.previousSibling).text(),
					next: d3.select(this.nextSibling).text(),
					getcurrent: function () {
						return this.current;
					},
					getnext: function () {
						return this.next;
					},
					getprevious: function () {
						return this.previous;
					}
				};
				silblings.push(entry);
			});

			let list = [];

			items.each(function () {
				var entry = {
					current: d3.select(this).text(),
					getcurrent: function () {
						return this.current;
					},
				}
				list.push(entry);
			});

			let current = g.selectAll('path').filter(function (el) {
				return el.data.id === target.data.id
			}).text();

			let outlist = [];
			outlist.push(current);

			let next;
			let curr = current;
			for (let i = 0; i <= silblings.length; i++) {
				let currsilb = silblings.find((s) => curr === s.getcurrent() ? s.getcurrent() : null);
				let findnext = list.find((s) => currsilb.getnext() === s.getcurrent());
				if (currsilb && currsilb.getnext() && findnext) {
					next = currsilb.getnext();
// eslint-disable-next-line no-unused-expressions
					outlist.indexOf(next) === -1 ? outlist.push(next) : null;
					curr = next;
				} else {
					break;
				}
			}

			let prev;
			curr = current;
			for (let i = 0; i <= silblings.length; i++) {
				let currsilb = silblings.find((s) => curr === s.getcurrent() ? s.getcurrent() : null);
				let findprev = list.find((s) => currsilb.getprevious() === s.getcurrent());
				if (currsilb && currsilb.getprevious() && findprev) {
					prev = currsilb.getprevious();
// eslint-disable-next-line no-unused-expressions
					outlist.indexOf(prev) === -1 ? outlist.push(prev) : null;
					curr = prev;
				} else {
					break;
				}
			}

			let out = g.selectAll("path")
				.filter(function (t) {
					let currpath = d3.select(this).text();
					return outlist.find((d) => d === currpath) ? t : null;
				});

			return out;
		}

		const getpath = (id) => {
//			console.log(g);
			return g.selectAll("path").filter(function (t) {
				return t.data.id === id ? t : null;
			})
		}
	};


	render() {
		return (
			<div id="svgContainer">
				{isIE ? (
					<svg id="partitionSVG" style={Object.assign({}, this.props.style, this.props.stylevers)}
					     height={this.state.width} viewBox={this.state.viewbox}></svg>
				) : (
					<svg id="partitionSVG" style={Object.assign({}, this.props.style, this.props.stylevers)}
					     width={this.state.width} viewBox={this.state.viewbox}></svg>
				)
				}
				{/*here:	{this.props.mysearch}{this.props.myfilter}*/}
			</div>
		);
	}

}

export default Sunburst
