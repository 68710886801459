import React, {Component} from 'react';
import * as d3 from "d3";
import './css/force.css';
import sunburst_svg from './img/sunburst.svg';
import minus_svg from './img/minus.svg';
import plus_svg from './img/plus.svg';
import _ from 'lodash';
import {forEach} from "react-bootstrap/cjs/ElementChildren";

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

class Force extends Component {

	constructor(props) {
		super(props)

		this.state = {
			scope: "collforce",
			data: this.props.data,
			viewbox: "0 0 700 700",
			width: this.props.width,
			initCompleted: false,
			lastClicked: null,
			lastSelected: null,
			lastSelectedId: null,
			lastDepth: null,
			currentzoomlevel: 0.2,
			initialzoomlevel: 0.2,
			delayduration: 2000,
			colorscheme: null,
			mysearch: this.props.mysearch,
			myfont: this.props.myfont,
			myversalien: this.props.myversalien,
			//	myfilter: this.props.myfilter,
			ini: {
				fontsize: 5,            // 1 normal, 2 double sized
				fontsize_def: [2, "normal", "large"],
				hiddenleafs: true,
				hiddenleafs_def: [2, "false", "true"],
				clickablenodes: false,
				clickablenodes_def: [1, "false", "true"],
				addtilde: ["Archäologie", "Naturkunde"]
			},
			_debug: false,
			_log: console.log,
			_warn: console.warn,
		};
		this.drawChart = this.drawChart.bind(this);
		this.handleClicked = this.handleClicked.bind(this);
		this.handleSelected = this.handleSelected.bind(this);
		this.getClicked = this.getClicked.bind(this);
//		this.getfillidx = this.getfillidx.bind(this);
	}

	l(string) {
		console.log("%c" + string, "color: blue");
	}

	myaction = (e) => {
		if (e === "clicked") {
			console.log("toggle clicked");
			this.props.toggleCallback();
		}
	};

	handleClicked = (d) => {
		this.setState({lastClicked: d});
		this.setState({lastDepth: d.datum().depth});
	};

	handleSelected = (d) => {
		this.setState({lastSelected: d.datum()});
		this.setState({lastSelectedId: d.datum().id});
	};

	getClicked = () => this.state.lastClicked ? this.state.lastClicked : null;

	componentDidMount() {
		this.l("----------> force layout: drawing now");
		this.drawChart();
		console.log(this.state);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.l("---------> force layout: updating now");
//		console.log("this.state.lastClicked");
//		console.log(this.state.lastClicked);
//		console.log("this.state.lastSelected");
//		console.log(this.state.lastSelected);
//		console.log("this.state.lastDepth");
//		console.log(this.state.lastDepth);

		// set symbol in cockpit to force-symbol
		d3.selectAll(".cockpit-nav-1").attr("class", "cockpit-nav cockpit-nav-1");
		d3.selectAll(".cockpit-nav-2").attr("class", "cockpit-nav cockpit-nav-2 active");
		d3.selectAll(".cockpit-nav-3").attr("class", "cockpit-nav cockpit-nav-3");

		if (prevProps.myfont !== this.props.myfont) {
			this.props.myfont
				? d3.selectAll("text").attr("font-face", "source_sans_proregular")
				: d3.selectAll("text").attr("font-face", "abel")
		}

		if (prevProps.myversalien !== this.props.myversalien) {
			this.props.myversalien
				? d3.selectAll("text").attr("text-transform", "uppercase")
				: d3.selectAll("text").attr("text-transform", "none")
		}

		if (prevProps.mynetcolor !== this.props.mynetcolor) {

//				this.forceUpdate();
//
//				// redraw chart
//				d3.selectAll("g").remove();
//				d3.selectAll("g").remove();
//				d3.selectAll(".tooltip").remove();
//				d3.selectAll("circle").remove();
//				d3.selectAll("#Layer_1").remove();
//				d3.selectAll("#Layer_2").remove();
//				d3.selectAll("#Layer_3").remove();
//				d3.selectAll("#Layer_4").remove();
////				d3.selectAll(".circletext").remove();
//				setTimeout(() => {
//					this.drawChart();
//				}, 100)

			//			this.props.mynetcolor
//				? d3.selectAll("text").attr("text-transform", "uppercase")
//				: d3.selectAll("text").attr("text-transform", "none")
		}

		if (prevProps.selectedImage !== this.props.selectedImage) {
			let selectedImage = this.props.selectedImage;

			if (selectedImage === 0) {
				this.unhighlight_gen();
			} else {
				// fetch & select
				let depth = this.state.lastDepth;
				let mypath = process.env.REACT_APP_ROOT_PATH + '/api/rest/cachedpath/id/' + selectedImage;
				fetch(mypath)
					.then(response => {
						return response.json();
					})
					.then(result => {
						if (result.length < 1) {
							return;
						}
						let pathnodes = result[0]['path_nodes'].split("|");
						let paths = pathnodes.filter(d => d !== "");
						paths.map(d => parseInt(d));

						this.highlight_gen(_.reverse(paths));
					});
			}
		}


//		console.log(this.state)
		// !!! C A U T I O N: use ONLY d3 code here, otherwise react will re-render the force layout with default values
	}

	componentUnmount() {
		this.l("----------> force layout: unmounting now");
		this._destroy_svg();
	}

	_destroy_svg() {
		this.l("----------> force layout: _destroy_svg()");
		this.svg && this.svg.selectAll('*').remove();
		this.svg = null
	}

	drawChart() {

//		d3.selectAll(".breadcrumb1").remove();

		var animationtimers = [];

		this.state._debug && this.state._log("------------> force layout: _drawChart()");

		const geti = (key) => this.state.ini[key];

		let circlewidth = 1,
			linewidth = 2;

		if (!this.props.data) return;
		console.log(this.props);
		console.log(this.state);

		var tick = 0;

		var timer = 0;

		const data = this.state.data;

		const delayduration = this.state.delayduration;

		const numFormat = d3.format(",d");
		const format = (d) => _.replace(numFormat(d), /,/g, '.');   // hotfix for thousands

		const width = this.state.width;

//		const width = 1000;
		const height = 680;

		const colorschemeArr = [
			{
				color: ['#c6dbef','#c6dbef', '#c6dbef', '#c6dbef', '#c6dbef'],
				collapsed:'#256793',
				fontcolor:'#102f44',
//				leaf: 'rgba(255,141,63,0.35)',
				leaf: new Array(5).fill('rgba(255,141,63,0.35)') ,
				leafopacity: 0.35,
				fill: '#3182bd',
				linestroke: '#9ecae1',
				circlestroke: '#3182bd',
				stroke: '#939598',
				strokesel: '#bd065f'
			},
			{
				color: new Array(5).fill('#e5cba4'),
				collapsed:'#256793',
				fontcolor:'#212121',
				leaf: new Array(5).fill('rgba(255,141,63,0.35)') ,
				fill: '#b0bac1',
				linestroke: '#a1a1a1',
				circlestroke: '#797979',
				stroke: '#939598',
				strokesel: '#bd065f'
			},
			{
				color: ['#cfcfcf','#EBD699', '#EB99D6', '#b0c58f','#99D6D6'],
//				color: ['#cfcfcf', '#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
				collapsed:'#256793',
				fontcolor:'#102f44',
				leaf: ['#cfcfcf','#EBD699', '#EB99D6', '#b0c58f','#99D6D6'],
//				leaf: ['#cfcfcf', '#E4A100', '#BC0A80', '#AAC482', '#009AB0'],
//				leaf: 'rgba(251,237,227,0.35)',
//				stroke: '#939598',
				linestroke: '#a1a1a1',
				circlestroke: '#797979',
				stroke: '#939598',
				strokesel: '#ff0000'
			}
		]

		const col = colorschemeArr[this.props.mynetcolor ? this.props.mynetcolor : 0];

		this.setState({colorscheme:col});

		let i = 0;

		const root = d3.hierarchy(data);

		let node, link;

		var div = d3.select(".breadcrumb1")
			.style("opacity", 0)
		;

		var {currentzoomlevel} = this.state;
		var {initialzoomlevel} = this.state;

		var zoom = d3.zoom()
			.scaleExtent([geti("fontsize") === 1 ? .5 : initialzoomlevel / 2, 3])
			.on("zoom", zoomed);

		const svg = d3
			.select("#partitionSVG")
			.style("width", isIE ? null : "100%")
			.style("height", isIE ? null : "auto")
			.call(zoom)

		const g = svg.append("g");

		zoom.scaleTo(svg, geti("fontsize") === 1 ? 1 : initialzoomlevel);
		zoom.translateTo(svg, 300, 300);

		d3.xml(sunburst_svg)
			.then(data => {

				d3.selectAll("#partitionSVG").node().append(data.documentElement);

				var img = d3.selectAll("svg#Layer_1");

				img.attr("x", width - 30)
					.attr("class", "svgbutton")
					.attr("y", 0)
					.attr("width", 50)
					.attr("height", 50)
					.style("cursor", "pointer")
					.attr("pointer-events", "all")
					.on("mouseover", function () {
						img.selectAll("path").attr("stroke", "#F0283F");
						tooltip1.style("visibility", "visible")
						console.log("mouse over");
					})
					.on("mouseout", function () {
						img.selectAll("path").attr("stroke", col.stroke);
						tooltip1.style("visibility", "hidden")
					})
					.on("click", toggle.bind(this))
			});

		d3.xml(minus_svg)
			.then(data => {

				d3.selectAll("#partitionSVG").node().append(data.documentElement);

				var img = d3.selectAll("svg#Layer_3");

				img.attr("x", width - 10)
					.attr("class", "svgbutton")
					.attr("y", height - 60)
					.attr("width", 30)
					.attr("height", 30)
					.style("cursor", "pointer")
					.attr("pointer-events", "all")
					.on("mouseover", function () {
						img.selectAll("rect").attr("stroke", "#F0283F");
						console.log("mouse over");
					})
					.on("mouseout", function () {
						img.selectAll("rect").attr("stroke", col.stroke);
					})
					.on("click", () => {
						console.log(currentzoomlevel);
//						zoom.scaleBy(svg, +0.25);
						if (currentzoomlevel > 0.11) {
							currentzoomlevel = currentzoomlevel - 0.05
							zoom.scaleTo(svg, currentzoomlevel);
							this.setState({currentzoomlevel: currentzoomlevel})
						}
//						zoom.translateBy(svg, 100,100);
//						g.attr("transform", d3.event.transform);
					})
			});

		var tooltip1 = svg.append("foreignObject")
			.style("visibility", "hidden")
			.attr("width", 140)
			.attr("height", 70)
			.attr("x", width-120)
			.attr("y", 45)
			.append("xhtml:div")
			.style("font-size", "0.75em")
			.style("background-color", "white")
			.style("border", "solid")
			.style("border-color", "lightgrey")
			.style("border-width", "0.5px")
			.style("border-radius", "1px")
			.style("padding", "5px")
			.style("z-index", 1300)
			.html('Klicken Sie hier für eine andere Ansicht');

		d3.select(".svgbutton")
			.on("mouseover", function(){return tooltip1.style("visibility", "visible");})
//			.on("mousemove", function(){return tooltip1
//				.style("top", (d3.select(this).attr("cx"))+"px")
//				.style("left",(d3.select(this).attr("cx"))+"px");})
			.on("mouseout", function(){return tooltip1.style("visibility", "hidden");});

		d3.xml(plus_svg)
			.then(data => {

				d3.selectAll("#partitionSVG").node().append(data.documentElement);

				var img = d3.selectAll("svg#Layer_4");

				img.attr("x", width - 10)
					.attr("class", "svgbutton")
					.attr("y", height-85)
					.attr("width", 30)
					.attr("height", 30)
					.style("cursor", "pointer")
					.attr("pointer-events", "all")
					.on("mouseover", function () {
						img.selectAll("polygon").attr("stroke", "#F0283F");
						console.log("mouse over");
					})
					.on("mouseout", function () {
						img.selectAll("polygon").attr("stroke", col.stroke);
					})
					.on("click", () => {
						console.log(currentzoomlevel);
						if (currentzoomlevel < 2) {
							currentzoomlevel = currentzoomlevel + 0.05
							zoom.scaleTo(svg, currentzoomlevel)
							this.setState({currentzoomlevel: currentzoomlevel})
						}
					})
			});


		d3.selectAll("#partitionSVG").append("rect")
			.attr("class", "myplus")
			.attr("width", 25)
			.attr("height", 20)
			.attr("x", width-10)
			.attr("y", height-78)
			.attr("stroke", "none")
			.attr("fill", "white")
			.attr("fill-opacity", 0.9)
//			.attr("fill", "green")
			.style("cursor", "pointer")
			.attr("pointer-events", "all")
			.on("mouseover", function () {
				d3.selectAll(".svgbutton").selectAll("polygon").attr("stroke", "#F0283F");
				console.log("mouse over");
			})
			.on("mouseout", function () {
				d3.selectAll(".svgbutton").selectAll("polygon").attr("stroke", col.stroke);
			})
			.on("click", () => {
				console.log(currentzoomlevel);
				if (currentzoomlevel < 2) {
					currentzoomlevel = currentzoomlevel+0.05
					zoom.scaleTo(svg, currentzoomlevel)
					this.setState({currentzoomlevel: currentzoomlevel})
				}
			})

		d3.selectAll("#partitionSVG").append("rect")
			.attr("class", "myminus")
			.attr("width", 25)
			.attr("height", 20)
			.attr("x", width-10)
			.attr("y", height-58)
			.attr("stroke", "none")
			.attr("fill", "white")
			.attr("fill-opacity", 0.9)
//			.attr("fill", "lightgreen")
			.style("cursor", "pointer")
			.attr("pointer-events", "all")
			.on("mouseover", function () {
				d3.selectAll(".svgbutton").selectAll("rect").attr("stroke", "#F0283F");
				console.log("mouse over");
			})
			.on("mouseout", function () {
				d3.selectAll(".svgbutton").selectAll("rect").attr("stroke", col.stroke);
			})
			.on("click", () => {
				console.log(currentzoomlevel);
				if (currentzoomlevel > 0.11) {
					currentzoomlevel = currentzoomlevel-0.05
					zoom.scaleTo(svg, currentzoomlevel)
					this.setState({currentzoomlevel: currentzoomlevel})
				}
			})


		var nodecoords = [

			{ name: "Naturkunde",                 init_x:-600, init_y: 1000, foci_x: -400, foci_y: 1000 },
			{ name: "Kulturgeschichte",           init_x: 1000, init_y: 800, foci_x: 1000, foci_y: 800  },
			{ name: "Literatur",                  init_x: 600, init_y: 900,  foci_x: 800,  foci_y: 1000 },
			{ name: "Rechtsgeschichte",           init_x: 1000, init_y: 700, foci_x:null,  foci_y:null  },
			{ name: "Rechtsgeschichte",           init_x: 1000, init_y: 700, foci_x:null,  foci_y:null  },
			{ name: "Spielzeug",                  init_x: 1000, init_y: 700, foci_x:null,  foci_y:null  },
			{ name: "Historische Landeskunde",    init_x: 1000, init_y: 700, foci_x:null,  foci_y:null  },
			{ name: "Volkskunde",                 init_x: 1000, init_y: 700, foci_x:null,  foci_y:null  },
			{ name: "Kunstsammlung",              init_x:-800, init_y: -300, foci_x: -800, foci_y: -300 },
			{ name: "Kunst",                      init_x:-900, init_y: -500, foci_x: -800, foci_y: 100  },
			{ name: "Karikatur",                  init_x:-500, init_y:  100, foci_x: -800, foci_y: 200  },
			{ name: "Kunst im öffentl. Raum",     init_x:-100, init_y: -300, foci_x: -100, foci_y: -200 },
			{ name: "Archäologie",                init_x: 800, init_y:-300,  foci_x: 200,  foci_y: -200 },
			{ name: "Urgeschichte und Historische Archäologie",     init_x: 100, init_y: -800, foci_x: 100,  foci_y: -800 },
			{ name: "Römische Archäologie",       init_x: 900, init_y: 200,  foci_x: 1000, foci_y:-300  }
		];

		const simulation = d3
		.forceSimulation()
		.force(
			"link",
			d3.forceLink().id(function (d) {
				return d.id;
			})
//				.distance(150)
//				.distance((d) => (6 - d.source.data.depth) * 20)
				.distance((d) => {

					let depth = 6 - d.source.depth;

					let indivdistance = 20;

					if (d.source.data.name === "Kulturgeschichte") { indivdistance = 400; }
					if (d.source.data.name === "Literatur") { indivdistance = 300; }
					if (d.source.data.name === "Kulturgeschichte" && d.target.data.name === "Literatur") { indivdistance = 500; }
					if (d.source.data.name === "Volkskunde") { indivdistance = 100; }
					if (d.source.data.name === "Rechtsgeschichte") { indivdistance = 100; }
					if (d.source.data.name === "Historisches Spielzeug") { indivdistance = 100; }
					if (d.source.data.name === "Historische Landeskunde") { indivdistance = 100; }
					if (d.source.data.name === "Historische Landeskunde" && d.target.data.name === "Personenbez. Sammlungen") { indivdistance = 300; }

					if (d.source.data.name === "Archäologie") { indivdistance = 450; }
					if (d.source.data.name === "Urgeschichte und Historische Archäologie") { indivdistance = 350; }
					if (d.source.data.name === "Römische Archäologie") { indivdistance = 350; }

					if (d.source.data.name === "Naturkunde") { indivdistance = 250; }
					if (d.target.data.name === "Naturkunde") { indivdistance = 750 + Math.random()*50; }

					if (d.source.data.name === "Kunstsammlung") { indivdistance = 200; }
					if (d.source.data.name === "Kunst") { indivdistance = 150; }
					if (d.source.data.name === "Karikatur") { indivdistance = 150; }
					if (d.source.data.name === "Kunst im öffentl. Raum") { indivdistance = 100; }

					return d.source.data.id === 1 ? 150 : 30 + indivdistance + Math.random()*20
				})
				.strength((d) => {
					let indivstrength = 0
//					if (d.source.data.name == "Naturkunde") { indivstrength = 0.1 }
					return d.source.data.id == 1 || d.target.data.id == 1 ? 0.3 + indivstrength : 1;
				})
//				.strength((d) => d.source.data.id == 1 && d.target.data.id == 1689 ? 0.2 : 1)
		)
		.force(
			"charge",
			d3
				.forceManyBody()
				.strength(-260)
				.distanceMax(500)
				.distanceMin(150)
		)
		.alphaMin(0.045)
		.alphaDecay(0.008)
		.velocityDecay(0.1)
//		.tick([200])
				.tick(100)

//		.force("r", d3.forceRadial( d => {
//			return d.depth * 150
//			}
//			).strength(.01))

//		.force("radial", d3.forceRadial( d => {
////				if (d.ancestor().data.name === "Naturkunde") {return 100 }
//				else {return 0}
//			}
//		).strength(.001))

		.force("collision",
			d3.forceCollide().radius(function (d) {

				let indivcollide = 15;

	            if (d.ancestors().find(f => f.data.name === "Naturkunde" && f.depth < 2)) { indivcollide = 40;}
	            if (d.ancestors().find(f => f.data.name === "Römische Archäologie")) { indivcollide = 30;}
	            if (d.depth === 0) { indivcollide = 100;}
	            if (d.depth === 1) { indivcollide = 100;}

				return (Math.sqrt(6-d.data.depth)) + 20 + indivcollide || 5 + indivcollide;
//				return (Math.sqrt(d.data.size) / 100000) + 10 || 4.5;
				}))
		.force("center", d3.forceCenter(width / 2, height / 4))
		.on("tick", ticked.bind(this))
		.on("end", function () {
			simulation.alphaMin(0.05)

//	save converged network positions
//
//				var nodeArray = {
//					nodes: [],
//					links: []
//				};
//				node.data().forEach(function (el) {
//					nodeArray.nodes.push({id: el.id, x: el.x, y: el.y});
//				});
//
//				link.data().forEach(function (el) {
//					nodeArray.links.push({
//						source: {id: el.source.id, x: el.source.x, y: el.source.y},
//						target: {id: el.target.id, x: el.target.x, y: el.target.y}
//					});
//				});
//
//				localStorage.setItem('nodeArray', JSON.stringify(nodeArray));
//				var out = localStorage.getItem('nodeArray');
////				console.log(out);
//				var parsed = JSON.parse(out);
////				console.log(parsed);
//
//				console.log(svg.selectAll(".node").filter((d)=>d.data.name==="Naturkunde"));
//				console.log(svg.selectAll(".link").filter((d)=>d.source.data.id < 2 && d.target.data.id > 1600));
//				console.log(svg.selectAll(".link").filter((d)=>d.source.data.id == 1 && d.target.data.id == 1689));
////				console.log(svg.selectAll(".link").each( (d) => console.log(d.target.id === 1); console.log(d)));

			});

		function forwardAlpha(layout, alpha, max) {
			alpha = alpha || 0;
			max = max || 1000;
			var i = 0;
			while (layout.alpha() > alpha && i++ < max) layout.tick();
		}

	function update() {
		const nodes = flatten(root);
		const links = root.links();

		console.log(geti('fontsize'));
		let fontscale = d3.scaleLinear().domain([0, 3000000]).range([10, 10 * geti("fontsize")]).clamp(false);
		let circlescale = d3.scaleLinear().domain([0, 3000000]).range([5000, 1000000]).clamp(false);

		link = g.selectAll(".link").data(links, function (d) {
			return d.target.id;
		});

		link.exit().remove();

		const linkEnter = link
			.enter()
//			.filter( function (d) {return d.target.data.size > 0 || d.target.children ? d: null;} )
			.append("line")
			.attr("class", "link")
			.attr("stroke", col.linestroke);
//            .style("stroke", "#000")
//            .style("opacity", "0.2")
//            .style("stroke-width", 2);

		link = linkEnter.merge(link);

//			console.log(nodes);

		node = g.selectAll(".node")
			.data(nodes, function (d) {
//				return d.data.size > 0 ? d.id : null;
				return d.id;
			});

		node.exit().remove();

		const nodeEnter = node
			.enter()
			.append("g")
			.attr("class", "node")
			.style("fill", color)
			
			.style("opacity", 1)
			.on("click", geti("clickablenodes") ? clicked.bind(this) : null)
			.on("mouseover", mouseover)
			.on("mouseout", mouseout)
			.call(d3.drag()
				.on("start", dragstarted)
				.on("drag", dragged)
				.on("end", dragended));

			nodeEnter
				.append("circle")
//				.style("opacity", 0.8)
				.style("stroke", col.circlestroke)
				.attr("class", (d) => d.children ? "nodecircle" : "leafcircle")
				.attr("r", function (d) {
					let sizearr = d.descendants().map(e => e.data.size ? e.data.size : 0);
					let size = sizearr.reduce((total, num) => total + num);
					return d.children ? Math.sqrt(circlescale(size)) / 15 : Math.sqrt(circlescale(d.data.size)) / 15 || 6;
//				return d.children ? circlesize(d.depth) : Math.sqrt(circlescale(d.data.size)) / 15 || 6;   // fixed circle sizes for non-leafs
				})
				.style("text-anchor", function (d) {
					return d.children ? "end" : "start";
				})
				.text(d => d.data.name);

			node.select("circle")
				.style("fill", color);

		// hidden circle to better catch mouse over
		nodeEnter
			.append("circle")
			.style("stroke", "#3182bd")
			.style("fill", "#3182bd")
			.attr("opacity", 0.3)
			.attr("visibility", d => d.children ? "hidden" : "visible")
			//			.attr("class", (d) => d.children ? "hidden_nodecircle" : "hidden_leafcircle")
			.attr("r", function (d) {;
				return 1.5;
			});


		// hidden circle to better catch mouse over
		nodeEnter
			.append("circle")
			.style("stroke", "#57bd42")
			.style("fill", "green")
			.attr("opacity", 0)
//			.attr("class", (d) => d.children ? "hidden_nodecircle" : "hidden_leafcircle")
			.attr("r", function (d) {;
				return 20;
			});


		nodeEnter.append("text")
			.attr("stroke-width", 0)
			.attr("stroke", "black")
			.attr("class", (d) => d.children ? "nodetext" : "leaftext")
			.attr("fill", function (d) {
				return d.children ? col.fontcolor : "black"
			})
			.attr("dy", ".35em")
//			.style("font-size", d => d.children ? 18 * geti("fontsize") - d.depth * 3 * geti("fontsize") : fontscale(d.data.size) + "px")
			.style("font-size", d => d.children ? fontsize(d.depth) +"px" : fontscale(d.data.size) + "px")
			.style("font-weight", function (d) {
				return d.children ? "bold" : ""
			})
			//                .attr("font-weight",function(d) {return d.size ? 300 : 800})
			.text(function (d) {
				return d.data.name;
			});

		// make textlabel clickable by using a rectangle below the text
		// first get the id of the rectangle 
		
		var txtid = [];
		var txtbox = [];

		const z = d3.selectAll(".nodetext")
			.attr("xx", function (d) {
				var obj = {id: d.data.id};
				txtid.push(obj);
			});

		// compute the size of the rectangle 
		z.nodes().map(function (d) {
				let bbox = d.getBBox();
				var obj = {
					x: bbox.x,
					y: bbox.y,
					width: bbox.width,
					height: bbox.height
				};
				txtbox.push(obj);
			}
		);

		// store id and size
		const txtmerge = _.merge(txtid, txtbox);
		
		// getter for rect height
		function ft_h(id) {
			let bbox = txtmerge.filter(x => x.id === id || x.id === parseInt(id));
			return bbox.length > 0 ? bbox[0].height : undefined;
		}

		// getter for rect width
		function ft_w(id) {
			let bbox = txtmerge.filter(x => x.id === id || x.id === parseInt(id));
			return bbox.length > 0 ? bbox[0].width : undefined;
		}

		nodeEnter.append("rect")
			.filter((d) => d.children ? true : false)
			.attr("width", d => ft_w(d.data.id))
			.attr("height", d => ft_h(d.data.id))
			.attr("x", d => 0 - ft_w(d.data.id) / 2)
			.attr("y", d => 0 - ft_h(d.data.id) / 2)
			.attr("fill", "blue")
			.attr("fill-opacity", 0.0)

		
		
		d3.selectAll(".leafcircle").attr("visibility", geti("hiddenleafs") ? "hidden" : "visible");
		d3.selectAll(".leaftext").attr("visibility", geti("hiddenleafs") ? "hidden" : "visible");

		node.raise();
		nodeEnter.raise();

		// place some big nodes initially on the outer borders to generate better layout

//		nodes.map(d => {
//			d.fx = 400;
//			d.fy = 400;
//		})

		if (getInitStatus() === false) {
			nodecoords.forEach(c => {
				nodes.filter( f => c.name === f.data.name).map(d => d.descendants().forEach( e => { e.fx = c.init_x; e.fy = c.init_y }));
			});
		}

		node = nodeEnter.merge(node);
		simulation.nodes(nodes);
		simulation.force("link").links(links);

		// Restart the force layout.
		simulation.restart();

//		forwardAlpha(simulation, 0.3, 50);
	}

	function circlesize(depth) {
		let size = [40, 24, 12, 10, 9, 8];
		return size[depth];
		}

	function fontsize(depth) {
			let size = [68, 50, 36, 24, 16, 12];
			return size[depth];
		}

	function sizeContain(num) {
		num = num > 1000 ? num / 1000 : num / 100;
		if (num < 4) num = 4;
		return num;
	}

	function getfillidx(d) {

		while(d.depth > 1) {d = d.parent;}

		let colidx = 0;

		if (d.data.name === "Archäologie") { colidx = 1}
		if (d.data.name === "Kulturgeschichte") { colidx = 2}
		if (d.data.name === "Naturkunde") { colidx = 3}
		if (d.data.name === "Kunstsammlung") { colidx = 4}

		return colidx;
	}


	function color(d) {

		return d._children
			? col.collapsed // collapsed package
			: d.children
				? col.color[getfillidx(d)] // expanded package
//				: "#c6dbef"; // leaf node in same color
				: col.leaf[getfillidx(d)]; // leaf node in other color
	}

	function radius(d) {
		return d._children ? 2 : d.children ? 2 : 1;
	}

	function ticked() {

		tick++;
		console.log("tick = " +tick);

		// use steps to speed up convergence on smaller alpha values

		if (tick === 1) {
			console.log("1st tick")
			// UN-set fixed positions

			node.attr("fx", d => d.fx = null);
			node.attr("fy", d => d.fy = null);
//
//			simulation.stop();

		}

		if (simulation.alpha() < 0.99 && simulation.alpha() > 0.9 && !this.state.initCompleted ) {
			console.log("< 0.999");

			simulation.alphaDecay(0.05);
			simulation.velocityDecay(0.2);
			forwardAlpha(simulation, 0.2, 15);

		}


		if (simulation.alpha() < 0.9 && simulation.alpha() > 0.3 && !this.state.initCompleted) {
			console.log("< 0.9");

			simulation.alphaDecay(0.005);
			simulation.velocityDecay(0.1);
			forwardAlpha(simulation, 0.02, 15);

		}

//		if (simulation.alpha() < 0.6 && !this.state.initCompleted) {
//			console.log("< 0.6");
//
//			forwardAlpha(simulation, 0.2, 2);
//			simulation.alphaDecay(0.01);
//			simulation.velocityDecay(0.05);
//		}

		if (simulation.alpha() < 0.4 && !this.state.initCompleted) {
			console.log("< 0.4");
			forwardAlpha(simulation, 0.003, 5);
			this.setState({initCompleted: true });
			simulation.alphaDecay(0.01);
			simulation.velocityDecay(0.1);
		}
////

		forwardAlpha(simulation, 0.05, 3);

		var k = 0.15 * simulation.alpha();

		// Push nodes toward their designated focus.
		node.each(function(o) {
			nodecoords.forEach(function (f) {
				if (o.data.name === f.name && f.foci_x) {
					o.x += (f.foci_x - o.x) * k;
					o.y += (f.foci_y - o.y) * k;
				}
			})
		});

		link.each(function(o) {
			nodecoords.forEach(function (f) {
				if (o.source.data.name === f.name && f.foci_x) {
					o.source.y += (f.foci_y - o.source.y) * k;
					o.source.x += (f.foci_x - o.source.x) * k;
					o.target.y += (f.foci_y - o.target.y) * k;
					o.target.x += (f.foci_x - o.target.x) * k;
				}
			})
		});


		link
			.attr("x1", function (d) {
				return d.source.x;
			})
			.attr("y1", function (d) {
				return d.source.y;
			})
			.attr("x2", function (d) {
				return d.target.x;
			})
			.attr("y2", function (d) {
				return d.target.y;
			});

		node.attr("transform", function (d) {
			return `translate(${d.x}, ${d.y})`;
		});
	}

	function toggle() {
		this.myaction("clicked");
	}

	function clicked(d) {
		if (!d3.event.defaultPrevented) {
			if (d.children) {
				d._children = d.children;
				d.children = null;
			} else {
				d.children = d._children;
				d._children = null;
			}

//			this.setState({lastClicked: d});
//			this.setState({lastDepth: d.depth});

			update();
		}
	}

	function mouseover(e) {
		const _self = d3.select(this);

		var labeltext;
		var labelcounter = 0;

		_self.selectAll(function (d) {
			labeltext = d.data.name;
		});

		var selid = _self;

		let descendants = Array.from(e.descendants());

		let delay = 200;
		if (descendants.length < 50) delay = 300;
		if (descendants.length > 200) delay = 20;
		if (descendants.length > 300) delay = 8;
		if (descendants.length > 500) delay = 2;
		if (descendants.length > 600) delay = 1;

		console.log("descendants");
		console.log(descendants);
		console.log(delay);

		highlight(e);
		highlight_parent(e);
		highlight_children(e, delay);
		let current_parent = e;
		for (let x = 0; x < 5; x++) {
			if (current_parent.parent) {
				labeltext = `${current_parent.parent.data.name}${" &rarr; "}${labeltext}`;
				highlight_parent(current_parent.parent);
				current_parent = current_parent.parent;
			}
		}

		descendants.forEach(d => {
			if (d.data.truesize) {
				labelcounter = labelcounter + d.data.truesize;
			}
		});

		let tilde = "", list = geti("addtilde");
		if (e.ancestors().find((a) => list.find((l) => a.data.name === l )) || e.descendants().find((a) => list.find((l) => a.data.name === l ))) {tilde = "~"}

		labeltext = `${labeltext}${" ["}${tilde}${format(labelcounter)}${"]"}`;

		addDelay(selid, labeltext);
		updateClicked(selid);
		updateSelected(selid);
		addTooltip(labeltext);
	}

	function mouseout(e) {

		for (var i = 0; i < animationtimers.length; i++)
		{
			clearTimeout(animationtimers[i]);
		}

		d3.selectAll("circle").style("stroke", col.circlestroke).style("stroke-width", circlewidth);
		d3.selectAll(".link").style("stroke", null);

		svg.selectAll('.tmptext').remove();

		d3.selectAll(".leafcircle").attr("visibility", geti("hiddenleafs") ? "hidden" : "visible");
		d3.selectAll(".leaftext").attr("visibility", geti("hiddenleafs") ? "hidden" : "visible");

		removeTooltip();
		deactivateSpinner();
		timer.stop();
	}

	function highlight(sel) {
		if (sel) {
			let _sel = d3.selectAll(".node").filter(d => d.id === sel.id ? d : null);
			_sel.select("circle").style("stroke", col.strokesel).style("stroke-width", circlewidth + "px");

			svg.selectAll('.tmptext').remove();

			let fontsize = _sel.selectAll("text").style("font-size");
			let X = _sel.selectAll("text").attr("x");
			let Y = _sel.selectAll("text").attr("y");
			let text = _sel.selectAll("text").text();
//			console.log("fontsize");
//			console.log(fontsize);

			_sel.append("text")
				.attr("stroke-width", 0)
				.attr("stroke", "black")
				.attr("class","tmptext")
				.attr("fill", "white")
				.attr("fill", col.strokesel)
				.attr("dy", ".35em")
				.style("font-size",   fontsize)
				.style("font-weight", function (d) {
					return d.children ? "bold" : ""
				})
				.text(function (d) {
					return d.data.name
				});

			_sel.raise();

			setTimeout(function(){
				_sel.selectAll('.tmptext').remove();
			}, 3500);

			activateSpinner();

			d3.selectAll(".leafcircle").filter(d => d.id === sel.id ? d : null).attr("visibility", "visible").attr("stroke-width", 0.1+"px");
			d3.selectAll(".leaftext").filter(d => d.id === sel.id ? d : null).attr("visibility", "visible");

		}
		return null;
	}

	function highlight_parent(sel) {

		let selparent = sel.parent;

		if (sel && selparent) {
			let _sel = d3.selectAll(".node").filter(d => d.id === sel.id || d.id === selparent.id ? d : null);
			_sel.select("circle").style("stroke", col.strokesel).style("stroke-width", circlewidth + "px");

			let _sel_link = d3.selectAll(".link").filter(function (d) {
				return d.source.data.id === selparent.data.id && d.target.data.id === sel.data.id ? d : null
			});
			_sel_link.style("stroke", col.strokesel).style("stroke-width", linewidth + "px");
		}
		return null;
	}

	function highlight_children(sel, delay) {

		sel.each(current_sel => {

			let selchildren = current_sel.children;

			if (current_sel && selchildren) {
				for (let x = 0; x < selchildren.length; x++) {

					if (delay === 1 && x%2 === 1) {
//					if (delay === 1 && x < 2) {
						dohighlight(current_sel, selchildren[x]);
					}
					else {
						animationtimers.push(setTimeout(function() {
							dohighlight(current_sel, selchildren[x]);
						},delay))
					}
				}
			}
			return null;
		});
	}

	function dohighlight(current_sel, curr_selchildren) {

		let _sel = d3.selectAll(".node")
			.filter(d => d.id === current_sel.id || d.id === curr_selchildren.id ? d : null)
			.select("circle")
			.style("stroke", col.strokesel)
			.style("stroke-width", circlewidth+"px");

		d3.selectAll(".leafcircle")
			.filter(d => d.id === current_sel.id || d.id === curr_selchildren.id ? d : null)
			.attr("visibility", "visible")
			.style("stroke-width", 0.1+"px");

		d3.selectAll(".leaftext").filter(d => d.id === current_sel.id || d.id === curr_selchildren.id ? d : null)
			.attr("visibility", "visible");

		let _sel_link = d3.selectAll(".link").filter(function (d) {
			return d.source.data.id === current_sel.data.id && d.target.data.id === curr_selchildren.data.id ? d : null
		});

		_sel_link
			.style("stroke", col.strokesel).style("stroke-width", linewidth+"px");
	}

	function addTooltip(path) {

		div.transition()
			.duration(200)
			.style("opacity", 1);

		div.style("visibility", "visible");

		// fixed position tooltips, now they are like breadcrumbs
		div.html((path))
			.style("left", "0 px")
			.style("top", "0 px");

		addSpinner();

	}

//		const delayduration = 4000;

	function removeTooltip(sel) {
		div.transition()
			.duration(200)
			.style("opacity", 0);
	}

	const addDelay = (_self, labeltext) => {
		if (_self !== null) {
			timer = d3.interval((elapsed) => {
				activateSpinner();
				console.log(elapsed + " self: " + _self.datum().data.id + " stored: " + checkselected());
				if (elapsed > delayduration) {
					timer.stop();
					deactivateSpinner();
					console.log("%ctimer stopped now", "color: white; background-color: green");
					fetchImagesNow(_self, labeltext);
				}
				;
			}, 500);
		}
	};

	const addSpinner = () => {
		div.append("span")
			.attr("class", "spinner spinner-border spinner-border-sm")
			.style("visibility", "hidden");
	}

	const activateSpinner = () => {
		div.select("span").style("visibility", "visible");
	};

	const deactivateSpinner = () => {
		div.select("span").style("visibility", "hidden");
		div.selectAll("li").selectAll("span").style("visibility", "hidden");
	};

	const getInitStatus = () => {
		return this.state.initCompleted;
		}

	const updateClicked = (sel) => {
		this.handleClicked(sel);
	};
	const updateSelected = (sel) => {
		this.handleSelected(sel);
	};

	const checkselected = () => {
		if (this.state.lastSelectedId !== null) {
			return this.state.lastSelectedId;
		}
	};


	const fetchImagesNow = (sel, labeltext) => {

		console.log("sel");
		console.log(sel);

		let out = {
			id: sel.datum().data.id,
			text: labeltext
		};
		return this.props.selectedCallback(out);
	}

	function dragstarted(d) {
		if (!d3.event.active) simulation.alphaTarget(0.3).restart();
		d.fx = d.x;
		d.fy = d.y;
	}

	function dragged(d) {
		d.fx = d3.event.x;
		d.fy = d3.event.y;
	}

	function dragended(d) {
		if (!d3.event.active) simulation.alphaTarget(0);
		d.fx = null;
		d.fy = null;
	}

	function flatten(root) {
		const nodes = [];
		function recurse(node) {
			if (node.children) node.children.forEach(recurse);
			if (!node.id) node.id = ++i;
			else ++i;
			nodes.push(node);
		}
		recurse(root);
		return nodes;
	}

	function zoomed() {
		g.attr("transform", d3.event.transform);
	}

	update();
};


highlight_gen (paths) {

	this.unhighlight_gen();

	let circlewidth = 5,
		linewidth = 4;

//	console.log("paths");
//	console.log(paths);

	const col = this.state.colorscheme;

	paths.map( target => {
		let _sel_link = d3.selectAll(".link").filter(function (d) {
			return d.target.data.id === target ? d : null
		});
		_sel_link.style("stroke", col.strokesel).style("stroke-width", linewidth + "px");

		let _sel = d3.selectAll(".node").filter(d => d.data.id === target ? d : null);
		_sel.select("circle").style("stroke", col.strokesel).style("stroke-width", circlewidth + "px");

		_sel.select("circle").style("stroke", col.strokesel).style("stroke-width", circlewidth + "px");

		d3.selectAll(".leafcircle").filter(d => d.data.id === target ? d : null).attr("visibility", "visible").attr("stroke-width", 0.1+"px");
		d3.selectAll(".leaftext").filter(d => d.data.id === target ? d : null).attr("visibility", "visible");
	})
	return null;
}

unhighlight_gen () {
	let circlewidth = 2;

	const col = this.state.colorscheme;

	d3.selectAll("circle").style("stroke", col.stroke).style("stroke-width", circlewidth);
	d3.selectAll(".link").style("stroke", null);

//	d3.selectAll('.tmptext').remove();

	d3.selectAll(".leafcircle").attr("visibility", "hidden");
	d3.selectAll(".leaftext").attr("visibility", "hidden");
}

	render(){
		return (
			<div id="svgContainer">
				{ isIE ? (
					<svg id="partitionSVG" style={Object.assign({},this.props.style,this.props.stylevers)} height={this.state.width} viewBox={this.state.viewbox}></svg>
				) : (
					<svg id="partitionSVG" style={Object.assign({},this.props.style,this.props.stylevers)} width={this.state.width} viewBox={this.state.viewbox}></svg>
				)
				}
			</div>
		);
	}

}

export default Force
