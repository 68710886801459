import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


const help_d_click = require('./img/help_d_click.svg');
const help_d_hover = require('./img/help_d_hover.svg');
const help_d_zoom = require('./img/help_d_zoom.svg');
//const help_d = require('./img/help_d.png');

const help_m_click = require('./img/help_m_click.svg');
const help_m_hover = require('./img/help_m_hover.svg');
const help_m_zoom = require('./img/help_m_zoom.svg');
//const help_m = require('./img/help_m.png');


class Help extends React.Component {

	constructor (props) {
		super(props);

		this.state = {
			scope: "Help",
			submitform: '',
			errormessage: ''
		}
	}

	l(string) {	console.log("%c"+string, "color: blue");}

	myaction = (e, t) => {

		if (t.type === "click" && e === "helpbtn_OK") {
			console.log("toggle clicked");
			this.props.helpCallback(e);
		} else if (t.type === "click" && e === "toggle1") {
			console.log("toggle1 clicked");
			this.props.toggleCallback1(e);
		}

	};

	mySubmitHandler = (event) => {
		event.preventDefault();
		this.setState({submitform: event.target}, function () { console.log(this.state);});
	};

	componentDidMount() {
		this.l("-----------> form mounted");
		console.log(this.state);
	}

	componentDidUpdate() {
		this.l("-----------> form updated");
//		console.log(this.state);
	}


	render() {
		return (
			<Container className='help-menu'>
				<Row>
					<Col sm={12}>
						{this.props.isMobile ?
							(
								this.props.sunburstFirst ?
									(
										<div>
											<img src={help_m_hover} className="float-left img-fluid" width={10 +"%"}/>
											<p>Drücken Sie länger auf einen Sammlungs&shy;bereich, um die Ansicht zu
												aktualisieren.</p>
											<img src={help_m_click} className="float-left img-fluid" width={10 +"%"}/>
											<p>Tippen Sie auf einen Sammlungsbereich, um diesen genauer zu
												erkunden.</p>
										</div>
									)
									:
									(
										<div>
											<img src={help_m_zoom} className="float-left img-fluid img-dbl" width={25 +"%"}  />
											<p>Nutzen Sie den zoom, um die Texte größer zu sehen, verschieben Sie durch wischen.</p>
											<img src={help_m_click} className="float-left img-fluid" width={10 +"%"}/>
											<p>Um die Objekte eines Sammlungs&shy;bereichs zu sehen, tippen Sie darauf.</p>
										</div>
									)
							)
							:
							(
								this.props.sunburstFirst ?
									(
										<div>
											<img src={help_d_hover} className="float-left img-fluid" width={8 +"%"}/>
											<p>Verweilen Sie kurz mit dem Mauszeiger auf einem Sammlungs&shy;bereich, um die Ansicht zu
												aktualisieren.</p>
											<img src={help_d_click} className="float-left img-fluid" width={8 +"%"} />
											<p>Klicken Sie auf einen Sammlungs&shy;bereich, um diesen genauer zu
												erkunden.</p>
										</div>
									)
									:
									(
										<div>
											<img src={help_d_zoom} className="float-left img-fluid" width={8 +"%"}/>
											<p>Nutzen Sie das Mausrad, um die Texte größer zu sehen, verschieben Sie mit
												geklickter linker Maustaste.</p>
											<img src={help_d_hover} className="float-left" width={8 +"%"}/>
											<p>Um die Objekte eines Sammlungsbereichs zu sehen, verweilen Sie für kurze
												Zeit mit dem Mauszeiger darauf.</p>
										</div>
									)
							)
						}

					</Col>
				</Row>
				<Row>
					<Col sm={9}> </Col>
					<Col sm={3}>
							<Button onClick={(ev) => this.myaction("helpbtn_OK", ev)} className="btn btn-sm" variant="secondary">OK</Button>
					</Col>
				</Row>

			</Container>
		);
	}
}

export default Help
