import PropTypes from 'prop-types';
import React from 'react';
import './css/imagegrid.css';
import Masonry from 'react-masonry-component';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import shuffleArray from 'shuffle-array';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import {stringify} from "query-string";
import * as d3 from "d3";
import empty from "d3-selection/src/selection/empty";
//import ReactGA from "react-ga";

const masonryOptions = {
	transitionDuration: 0,
	stagger: 0,
	gutter: 3,
	itemSelector: '.grid-item',
	columnWidth: '.grid-item',
	fitWidth: true,
	hiddenStyle: {
		transform: 'translateY(0px)',
		opacity: 1
	},
	visibleStyle: {
		transform: 'translateY(0px)',
		opacity: 1
	},
//	columnWidth: '.grid-sizer',
	percentPosition: true
};

class ImageGrid extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			scope: "ImageGrid",
			images: this.props.images,
			imageIdx: 0,
			laterimages: null,
			page: 0,
			pages: 0,
			randFaveArray: [],
			randNonFaveArray: [],
			addToNonFaves: [],
			fetching: false,
			show: 0,
			selid: null,
			imageIndex: 0,
			isOpen: false,
			wasOpen: false,
			imageheader: 'https://www.online.landessammlungen-noe.at/internal/media/dispatcher/'
		};

		this.onSelectImage = this.onSelectImage.bind(this);
		this.onClick = this.onClick.bind(this);
		this.mouseEnter = this.mouseEnter.bind(this);
		this.mouseLeave = this.mouseLeave.bind(this);
		this.fetchit = this.fetchit.bind(this);
		this.handleLayoutComplete = this.handleLayoutComplete.bind(this);
		this.addImages = this.addImages.bind(this);

	}

	l(string) {	console.log("%c"+string, "color: blue");}


	componentDidMount() {
		this.l("----------> gallery: starting now");
		console.log(this.state);
//		console.log(this.props);
		this.fetchit(1);  // fetch initial view
		this.setState( {
			selid : 1,
		});
		window.addEventListener('scroll', _.throttle(this.doThrottle, 1000));
		this.masonry.on('layoutComplete', this.handleLayoutComplete);

		// set default font in body
		d3.selectAll("body")
			.style("font-family", this.props.myfont ? "abel" : "source_sans_proregular")
			.style("text-transform", this.props.myversalien ? "uppercase" : "none");

		this.l("----------> gallery: starting done");
	}

	doThrottle = () => {
		this.getElementPostion = this.listenToScroll();
	}

	componentDidUpdate(nextProps, nextState) {

		this.l("---------> gallery: updating now");

//		console.log(this.state);
////		console.log(nextState);
//		console.log(this.props);
////		console.log(nextProps);

//		console.log("masonry: fetch");

		const{ selectedNode } = this.props;

		if (nextProps.selectedNode !== selectedNode) {
			if (selectedNode) {
				window.scrollTo(0, 0);
				this.setState({ fetching: true, page: 0})
				this.fetchit(this.props.selectedNode, false, true);
			}
		}

		if (this.state.laterimages && this.checklater(this.state.page) ) {
			this.addImages(this.state.laterimages);
			this.setState( {laterimages: null});
		}

		// fetch if there are not enough images for scroll listener to start
//		if (this.state.page < nextProps.screenwidth/200  && this.state.randNonFaveArray.length > 0 && !this.state.fetching) {
		if (this.state.page === 0  && this.state.randNonFaveArray.length > 0 && !this.state.fetching) {
			this.setState({ fetching: true})
			this.fetchit(this.props.selectedNode, true, false);
		}

//		this.masonry.reloadItems();
//		this.masonry.layout();

		// avoid unintentional scrolling when lightbox is started or stopped
		if (this.state.isOpen === false && nextState === true) {
			window.scrollTo(0, 0);
		}

		// font change via configurator
		if (nextProps.myfont !== this.props.myfont || nextProps.myversalien !== this.props.myversalien) {
			d3.select('body').style("font-size", 10.5 + (this.props.myfont ? 3 : 2) + (this.props.myversalien ? -2 : -1));
			d3.selectAll("body")
				.style("font-family", this.props.myfont ? "abel" : "source_sans_proregular")
				.style("text-transform", this.props.myversalien ? "uppercase" : "none");
		}

		this.l("---------> gallery: updating done");
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		const empty = this.props.images;
		if (nextState.images === empty) {
			return false;
		}
		return true;
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.listenToScroll)
	}

	listenToScroll = () => {
		const winScroll =
			document.body.scrollTop || document.documentElement.scrollTop

		const height =
			document.documentElement.scrollHeight -
			document.documentElement.clientHeight;

		const scrolled = winScroll / height

		let truepercent = scrolled / (this.state.images.length);

		let items = this.state.images.length;
		let curritem = scrolled * items;
		let itemsleft = items - curritem;
		let crititem = items - 550;

//		console.log("scrolled: "+scrolled+"  "+this.state.page + " " + truepercent );
//		console.log("items: "+items+ " curritem: " + curritem + " itemsleft: " + itemsleft + "crititem: "+ crititem );

		if (curritem > crititem && !this.state.fetching) {
			console.log("... and now, the end is near ...");
			this.setState({fetching : true})
			this.fetchit(this.props.selectedNode, true, false);
		}
	};


	fetchit(id, next = false, zeropage = false) {
//		console.log("selid:" + this.state.selid);
//		console.log("sel:" + this.state.sel);
//		console.log("id:" + id);
//		console.log("page:" + this.state.page);
//		console.log("next:" + next);

		let currPageIndex = this.state.page;
		if (next) {
			currPageIndex++;
		}

		if (zeropage) {
			console.log("ZERO PAGE");
			currPageIndex = 0;
			this.setState({ page: 0, images: this.props.images, randNonFaveArray: [], randFaveArray: [], addToNonFaves: [] });
		}

		if (id !== this.state.sel || next) {
//			console.log(this.state.images);
//			console.log("fetch now: " + id);
//			console.log(process.env.REACT_APP_ROOT_PATH);
			console.log("----> loading data record # " + id);

			if (!id) {
				id = 1;
			}

			let page = zeropage ? 0 : this.getRandomPage(currPageIndex);
//			console.log("currpage");
//			console.log(currPageIndex);
//			console.log("page");
//			console.log(page);

			// stop getting results when reaching end of pages
			if (currPageIndex > 0 && currPageIndex >= this.state.pages) {
				return null
			}

			if (page === undefined) return null;

			// fetch id now
			fetch(process.env.REACT_APP_ROOT_PATH + '/api/rest/cachedimages/id/' + id + '/page/' + page + '/seed/' +this.props.currRandom )// todo set this to cachedimage/id/$id
				.then(response => {
					return response.json();
				})
				.then(result => {
					console.log("%c FETCHING id: " + id + ", p: " + currPageIndex, "background-color: orange");

					const maxpage = result['maxpage'];
					const maxfavepage = result['maxfavepage'];

					if (next) {
						if (result['faves'] && !result['nonfaves']) {
							let added = this.state.images.concat(result['faves']);
							this.setState({images: added, page: currPageIndex, fetching: false, sel: id, pages: maxpage});
						} else if (!result['faves'] && result['nonfaves']) {
							let added = this.state.images.concat(result['nonfaves']);
							this.setState({images: added, page: currPageIndex, fetching: false, sel: id, pages: maxpage});
						} else if (result['faves'] && result['nonfaves']) {
							let added = this.state.images.concat(result['faves']);
							let addedlater = result['nonfaves'];
							this.setState({
								images: added, laterimages: addedlater, page: currPageIndex, fetching: false, sel: id, pages: maxpage
								});
						}
					} else {
						if (result['faves'] && !result['nonfaves']) {
							this.setState({images: shuffleArray(result['faves']), page: currPageIndex, fetching: false, sel: id, pages: maxpage})
						} else if (!result['faves'] && result['nonfaves']) {
							this.setState({images: result['nonfaves'], page: currPageIndex, fetching: false, sel: id, pages: maxpage})
						} else if (result['faves'] && result['nonfaves']) {
							this.setState({
								images: shuffleArray(result['faves']), laterimages: result['nonfaves'],
								page: currPageIndex, fetching: false, sel: id, pages: maxpage});
						}
					}
					if (currPageIndex === 0) {
						this.makeRandomArray(maxpage, maxfavepage);
					}
				});
		}
	}

	// check if current page is the one where we can add non-faves
	checklater(currPageIndex) {

		let {randFaveArray, randNonFaveArray, addToNonFaves} = this.state;

		if (currPageIndex === 0 && randNonFaveArray.length === 0) { return true }

		if (addToNonFaves && currPageIndex === addToNonFaves) {
			return true
		}
		else return false
	}

	// return random page
	getRandomPage(currPageIndex) {

		let {randFaveArray, randNonFaveArray, addToNonFaves} = this.state;

		// if zero page => do not get page index
		if (currPageIndex === 0) {
			return 0
		} else {
			if (currPageIndex <= randFaveArray.length) {
				return randFaveArray[currPageIndex-1]
			} else if (currPageIndex > randFaveArray.length) {
				return randNonFaveArray[currPageIndex-randFaveArray.length]
			}
		}
	}

	makeRandomArray(maxpage, maxfavepage) {

		let randFaveArray = [];
		if (maxfavepage > 0) {
			randFaveArray = Array.from(Array(maxfavepage-1)).map((e,i)=>i+1)
		}

		let randNonFaveArray = Array.from(Array(maxpage-maxfavepage)).map((e,i)=>maxfavepage-1+i+1)

		randFaveArray = shuffleArray(randFaveArray);
		randNonFaveArray = shuffleArray(randNonFaveArray);

		var addToNonFaves = randNonFaveArray[Math.floor(Math.random() * randNonFaveArray.length)];

		this.setState({
			randFaveArray: randFaveArray,
			randNonFaveArray: randNonFaveArray,
			addToNonFaves: addToNonFaves
		})

	}

	onClick(d) {

		// return if no image has been clicked
		if (!d.target.id) {
			return;
		}

		// google analytics image
		let text = "image full view: "+d.target.src;
//		ReactGA.event({
//			category: 'Vis',
//			action: text
//		});

		this.setState({
			imageIndex: d.target.id,
			isOpen: true,
			wasOpen: false
		});
	}

	onSelectImage (index, image) {
		var images = this.state.images.slice();
		var img = images[index];

		if(img.hasOwnProperty("isSelected"))
			img.isSelected = !img.isSelected;
		else
			img.isSelected = true;

		this.setState({
			images: images
		});
	}


	mouseEnter(e) {
		try {
			if (e.target.id && this.state.images[e.target.id].id) {
				const imageId = this.state.images[e.target.id].id;
				this.props.selectionCallback(imageId);
			}
		}
		catch {
			console.log(e);
		}
	}

	mouseLeave(e) {
		if (e.target.id) {
			this.props.selectionCallback(0);
		}
	}

	handleImagesLoaded(imagesLoadedInstance) {
		console.log("images loaded");
	}

	handleLayoutComplete() {
		console.log("masonry: layout COMPLETE");
	}

	addImages(images2add) {
			const imagearr = this.state.images;
			let mergedArray;
			if (images2add && imagearr) {
				mergedArray = imagearr.concat(images2add);
				this.setState({images: mergedArray});
			}
	}

	render() {

		if (!this.state.images) {
			return null
		}

		if (this.state.images.length === 0) {

			return (
				<div>
					<p></p>
					<p>
						Wir arbeiten laufend an der Digitalisierung unserer Sammlung, kommen Sie
						bald wieder einmal vorbei!
					</p>
				</div>
			)
			console.log("empty images", this.state.images);
		}

//		console.log("images", this.state.images);

		const imagesLoadedOptions = {background: '.my-bg-image-el'};

		const fontstyle = Object.assign({},this.props.style,this.props.stylevers);

		const thumbsize = this.props.mythumbsize ? "orig/" : "thumb/";

		const {imageheader} = this.state;

		const images = [];
		const imagetitles = [];
		var i = -1;

		const childElements = this.state.images.map(function (element) {
			i++;
			images[i] = element.src;
//			var url = _.replace(_.replace(element.src, 'full', ''), 'internal/media/dispatcher', 'objects');
			var url = "https://www.online.landessammlungen-noe.at/objects/" + element.sourceID;
			var imgurl = process.env.REACT_APP_ROOT_PATH + "/api/assets/cache/" + thumbsize + element.thumbnailcache;
			imagetitles[i] =
				<div className='imgtitle'>{element.caption}.&nbsp; <a href={url} className="fullview" target='_blank' rel="noopener noreferrer" style={fontstyle} >Klicken Sie hier für Details (in neuem Tab)
					&nbsp;&nbsp;<i><FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></i></a></div>;

			let width = element.thumbnailWidth;
			let height = element.thumbnailHeight;

			let w = 150;    // see .grid-item img in css
			let ratio = width / w;
			let h = Math.floor(height / ratio);

			let styletag = 'height:' + h + 'px with:' + w + 'px;';

			const divStyle = {
				height: h,
				width: w
			};

			return (
				<li key={i} className='grid-item'>
					<LazyLoad
						key={i}
						height={h}
						width={w}
						once={true}
//						scrollContainer={document.getElementsByClassName("containerDIV")}
						resize={true}
						throttle={1300}
						debounce={1300}
//						overflow={true}
						offset={[3000, 3000]}    // no shuffling even when scrolling fast
//							offset={[900000, 900000]}    // only needed for debugging
//							offset={[-200, -200]}    // only needed for debugging
						unmountIfInvisible={false} // only needed for debugging
						placeholder=
							{
								<div key={i} className="placeholder d-flex justify-content-center align-items-center"
								     style={divStyle}>
									<div className="spinner-border text-muted align-self-center" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							}
					>
						{/*<a href={element.src}> </a>*/}
						<div className="img-hover-zoom">
							<img id={i}
//							     src={isNaN(element.thumbnail) ? element.thumbnail : imageheader + element.thumbnail + '/thumbnail'}  // check if empty.png
                                 src={isNaN(element.thumbnail) ? element.thumbnail : imgurl}  // check if empty.png
							     height={h} width={w}
//							     alt={element.caption} title={element.caption}
							     title={element.caption}
//							     onMouseEnter={this.mouseEnter}
//							     onMouseLeave={this.mouseLeave}
							/>
						</div>

					</LazyLoad>
				</li>
			);
		});

		let {imageIndex, isOpen} = this.state;

		imageIndex = parseInt(imageIndex);

		return (

			<div className="containerDIV" id="scrollcontainer" style={Object.assign({},this.props.style,this.props.stylevers)}>
				{isOpen && (<Lightbox
						mainSrc={imageheader + images[imageIndex] + '/full'}
						imageTitle={imagetitles[imageIndex]}
						nextSrc={imageheader + images[(imageIndex + 1) % images.length] + '/full'}
						prevSrc={imageheader + images[(imageIndex + images.length - 1) % images.length] + '/full'}
						onCloseRequest={() => this.setState({isOpen: false, wasOpen: true})}
						onMovePrevRequest={() =>
							this.setState({
								imageIndex: (imageIndex + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								imageIndex: (imageIndex + 1) % images.length,
							})
						}
						reactModalStyle={{overlay: {zIndex: "10001"}}} // set very high to overlay header
					/>
				)}
				<Masonry
					className={'grid card-columns'} // default ''
					elementType={'ul'} // default 'div'
					options={masonryOptions} // default {}
					onClick={this.onClick}
					onMouseOver={this.mouseEnter.bind(this)}
					onMouseOut={this.mouseLeave.bind(this)}
					ref={function (c) {
						this.masonry = this.masonry || c.masonry;
					}.bind(this)}
					updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
				//	updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
				//	enableResizableChildren={true}
					onImagesLoaded={this.handleImagesLoaded}
					disableImagesLoaded={false} // default false
				//	disableImagesLoaded={true} // default false
					imagesLoadedOptions={imagesLoadedOptions} // default {}
				>
					{childElements}
					<div className="my-bg-image-el"/>
				</Masonry>
			</div>

		);
	}

}

ImageGrid.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.shape({
			src: PropTypes.string.isRequired,
			thumbnail: PropTypes.string.isRequired,
			srcset: PropTypes.array,
			caption: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.element
			]),
			thumbnailWidth: PropTypes.number.isRequired,
			thumbnailHeight: PropTypes.number.isRequired,
			isSelected: PropTypes.bool
		})
	).isRequired
};

ImageGrid.defaultProps = {
// eslint-disable-next-line no-undef
	images: shuffleArray([
		{
			src: process.env.REACT_APP_PUBLIC_PATH + '/empty.png',
			thumbnail: process.env.REACT_APP_PUBLIC_PATH + '/empty.png',
			thumbnailWidth: 327,
			thumbnailHeight: 523,
			caption: ""
//		},
//		{
//			src: "http://www.online.landessammlungen-noe.at/internal/media/dispatcher/176368/full",
//			thumbnail: "http://www.online.landessammlungen-noe.at/internal/media/dispatcher/176368/thumbnail/",
//			thumbnailWidth: 327,
//			thumbnailHeight: 604,
//			caption: "BERTLMANN/DASCHNER (Variante 2)"
//		}, {
//			src: "http://www.online.landessammlungen-noe.at/internal/media/dispatcher/7161/full",
//			thumbnail: "http://www.online.landessammlungen-noe.at/internal/media/dispatcher/7161/thumbnail/",
//			thumbnailWidth: 327,
//			thumbnailHeight: 293,
//			caption: "Blumen"
		}
	])
};

export default ImageGrid;



