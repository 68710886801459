import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Sunburst from "./Sunburst";


class Configurator extends React.Component {

	constructor (props) {
		super(props);

		this.state = {
			scope: "Form",
			submitform: '',
		//	myfilter: this.props.myfilter,
			mysearch: this.props.mysearch,
			errormessage: ''
		}
	}

	l(string) {	console.log("%c"+string, "color: blue");}

	myaction = (e, t) => {

		if (t.type === "click" && e === "toggle") {
			console.log("toggle clicked");
			this.props.toggleCallback(e);
		} else if (t.type === "click" && e === "toggle1") {
			console.log("toggle1 clicked");
			this.props.toggleCallback1(e);
		}
	};

	myChangeHandler = (event) => {

		let nam = event.target.name;
		let val = event.target.value;
		let err = '';
		if (nam === "search") {
			if (val !== "" && Number(val)) {
				err = <strong>Search must be a string</strong>;
			}
		}
		this.setState({errormessage: err});
			this.props.filterCallback(val);
		this.setState({[nam]: val}, function () {
			console.log(this.state);
			//this.props.filterCallback(nam);
			//this.props.searchCallback(this.state.mysearch);
		});
	};

	myChangeHandlerColor = (event) => {

		let nam = event.target.name;
		let val = event.target.value;
		let err = '';
		this.setState({errormessage: err});
		this.props.colorCallback(val);
		this.setState({[nam]: val}, function () {
			console.log(this.state);
			//this.props.filterCallback(nam);
			//this.props.searchCallback(this.state.mysearch);
		});
	};

	myChangeHandlerNetColor = (event) => {

		let nam = event.target.name;
		let val = event.target.value;
		let err = '';
		this.setState({errormessage: err});
		this.props.colorNetCallback(val);
		this.setState({[nam]: val}, function () {
			console.log(this.state);
		this.props.toggleCallback1(true);
			//this.props.filterCallback(nam);
			//this.props.searchCallback(this.state.mysearch);
		});
	};

	myChangeHandlerDesign = (event) => {

		let nam = event.target.name;
		let val = event.target.value;
		let err = '';
		this.setState({errormessage: err});
		this.props.designCallback(val);
		this.setState({[nam]: val}, function () {
			console.log(this.state);
//			this.props.toggleCallback1(true);
			//this.props.filterCallback(nam);
			//this.props.searchCallback(this.state.mysearch);
		});
	};


	mySwitchHandler = (event) => {
		let label = event.target.label;
		let checked = event.target.checked;
		let id = event.target.id;
		console.log(label)

		this.setState({[id]: checked}, function () {
			console.log(this.state);
			this.props.switchCallback(id, checked);
			//this.props.searchCallback(this.state.mysearch);
		});
	}

	mySubmitHandler = (event) => {
		event.preventDefault();
		this.setState({submitform: event.target}, function () { console.log(this.state);});
	};




	componentDidMount() {
		this.l("-----------> form mounted");
		console.log(this.state);
	}

	componentDidUpdate() {
		this.l("-----------> form updated");
//		console.log(this.state);
	}


	render() {
		return (
			<Container className='configurator-menu'>
					<Form onSubmit={this.mySubmitHandler}>
						<Row>
							<Col sm={3}><h6>Allgemein</h6></Col>
						</Row>

						<Form.Row>
							{/*<Col-4>*/}
							{/*<Form.Group controlId="FormGroupSearch">*/}
							{/*	<div className="input-group">*/}
							{/*		<div className="input-group-prepend">*/}
							{/*			<button*/}
							{/*				id="button-addon8"*/}
							{/*				type="submit"*/}
							{/*				className="btn btn-secondary">*/}
							{/*				<i><FontAwesomeIcon icon={faSearch}/></i>*/}
							{/*			</button>*/}
							{/*		</div>*/}
							{/*		<input*/}
							{/*			type="search"*/}
							{/*			name='search'*/}
							{/*			value={this.state.mysearch}*/}
							{/*			onChange={this.myChangeHandler}*/}
							{/*			placeholder="What're you searching for?"*/}
							{/*			aria-describedby="button-addon8"*/}
							{/*			className="form-control"*/}
							{/*		/> */}
							{/*	</div>*/}
							{/*</Form.Group>*/}
							{/*&nbsp;*/}
							{/*</Col-4>*/}
							<Col sm={6}>
							{/*<Form.Group controlId="FormGroupList">*/}
								<Form.Control as="select"
								              size = "sm"
								              name='myfilter'
								              value={this.props.myfilter}
								              onChange={this.myChangeHandler}
								>
									<option value="0">Kunst</option>
									<option value="1">Kulturgeschichte</option>
									<option value="2">Archäologie</option>
									<option value="3">Naturgeschichte</option>
									<option value="4">Alle Sammlungen</option>
								</Form.Control>
							{/*</Form.Group>*/}
							</Col>
							{/*<Col-2>*/}
							{/*<Form.Group>*/}
							{/*	<Button onClick={(ev) => this.myaction("send", ev)} variant="success">Send</Button>*/}
							{/*</Form.Group>*/}
							{/*</Col-2>*/}
								<Col sm={6}>
									<Form.Group>
										&nbsp;
										<Button onClick={(ev) => this.myaction("toggle", ev)} className="btn btn-sm" variant="secondary">toggle chart</Button>
									</Form.Group>
								</Col>
						</Form.Row>


						<Row>
							<Col sm={2}>Font:</Col>
							<Col sm={2} className="text-right">normal</Col>
							<Col sm={8}>
								<Form.Check
									id="myversalien"
									type="switch"
									label="VERSALIEN"
									checked={this.props.myversalien}
									onChange={this.mySwitchHandler}
								/>
							</Col>
						</Row>

						<Row>
							<Col sm={2}></Col>
							<Col sm={2} className="text-right" style={this.div_stlye}>Font1</Col>
							<Col sm={8}>
								<Form.Check
									id="myfont"
									type="switch"
									label="Font2"
									checked={this.props.myfont}
									onChange={this.mySwitchHandler}
								/>
							</Col>
						</Row>

						<Row>
							<Col sm={3}><h6>Images</h6></Col>
						</Row>

						<Row>
							<Col sm={2}>thumbnail Dateigröße:</Col>
							<Col sm={2} className="text-right">klein</Col>
							<Col sm={8}>
								<Form.Check
									id="mythumbsize"
									type="switch"
									label="groß"
									checked={this.props.mythumbsize}
									onChange={this.mySwitchHandler}
								/>
							</Col>
						</Row>



						<Row>
							<Col sm={3}><h6>Sunburst</h6></Col>
						</Row>

						<Row>
							<Col sm={2}>Reihenfolge:</Col>
							<Col sm={2} className="text-right">Größe</Col>
							<Col sm={8}>
								<Form.Check
									id="myorderchron"
									type="switch"
									label="chronologisch"
									checked={this.props.myorderchron}
									onChange={this.mySwitchHandler}
								/>
							</Col>
						</Row>

						{/*<Row>*/}
						{/*	<Col sm={2}>Auswahl:</Col>*/}
						{/*	<Col sm={2} className="text-right">nur Rand</Col>*/}
						{/*	<Col sm={8}>*/}
						{/*		<Form.Check*/}
						{/*			size="sm"*/}
						{/*			id="mysegmentfill"*/}
						{/*			type="switch"*/}
						{/*			label="ganzes Segment"*/}
						{/*			checked={this.props.mysegmentfill}*/}
						{/*			onChange={this.mySwitchHandler}*/}
						{/*		/>*/}
						{/*	</Col>*/}
						{/*</Row>*/}
						<Row>
							<Col sm={8}>Design:
								<Form.Control as="select"
								              size = "sm"
								              name='mycolor'
								              value={this.state.mydesign}
								              onChange={this.myChangeHandlerDesign}
								>
									<option value="opaque"> Design 1 - Farbe, Segment</option>
									<option value="color">  Design 2 - Farbe, Rahmen</option>
									<option value="gray">   Design 3 - Grau, Segment</option>
								</Form.Control>
							</Col>
						</Row>
						<Row>
							<Col sm={8}>Farbe:
							<Form.Control as="select"
							              size = "sm"
							              name='mycolor'
							              value={this.props.mycolor}
							              onChange={this.myChangeHandlerColor}
							>
								<option value="0">Schema1</option>
								<option value="1">Schema2</option>
								<option value="2">Schema3</option>
								<option value="3">Schema4</option>
								<option value="4">Schema5</option>
								<option value="5">Schema6</option>
								<option value="6">Schema7</option>
								<option value="7">Schema8</option>
								<option value="8">Schema9</option>
								<option value="9">Schema10</option>
								<option value="10">Schema11 default für design 1,3</option>
								<option value="11">Schema12 wie 11 für design 2</option>
								<option value="12">Schema13 anderer farbton design 1,3 </option>
								<option value="13">Schema14 originalgrün design 1,3 </option>
							</Form.Control>
							</Col>
						</Row>

						<Row>
							<Col sm={3}><h6>Network</h6></Col>
						</Row>

						<Row>
							<Col sm={6}>Farbe:

								<Form.Control as="select"
								              size = "sm"
								              name='mynetcolor'
								              value={this.props.mynetcolor}
								              onChange={this.myChangeHandlerNetColor}
								>
									<option value="0">Schema1 blue</option>
									<option value="1">Schema2 beige</option>
									<option value="2">Schema3 juicy</option>
								</Form.Control>
							</Col>
							{/*<Col sm={3}> */}
							{/*		&nbsp;*/}
							{/*	<Form.Group>*/}
							{/*		<Button onClick={(ev) => this.myaction("toggle1", ev)} className="btn btn-sm" variant="secondary">refresh</Button>*/}
							{/*	</Form.Group>*/}
							{/*</Col>*/}
						</Row>

					</Form>
			</Container>
		);
	}
}

export default Configurator
