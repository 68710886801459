import React, {Component} from 'react';

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// dummy component, used to make a fresh red-render of other d3-components possible
// this component is actually only used to instantly restart the network component
class Empty extends Component {

	constructor(props) {
		super(props)

		this.state = {
			scope: "empty",
			data: this.props.data,
			_debug: false,
			_log: console.log,
			_warn: console.warn,
		};
	}

	l(string) {console.log("%c" + string, "color: blue");}

	componentDidMount() {
		this.l("----------> empty layout: drawing now");
		this.props.toggleCallback1(true);
	}

	render(){
		return (
			<div id="svgContainer">
				{/*intentinally nothing*/}
			</div>
		);
	}

}

export default Empty
